import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import DatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"
import "./DateRange.scss"

const DateRange = ({ before, after, handleChange }) => {
  const afterDate = moment(after).toDate()
  const afterTime = moment(after).format("h:mm:ss A")
  const beforeDate = moment(before).toDate()
  const beforeTime = moment(before).format("h:mm:ss A")

  const d = new Date()
  const minDate = d.setDate(d.getDate() - 89)

  const handleAfterChange = date => {
    if(date && moment(new Date(date)).isValid()) {
      handleChange(moment(date).toISOString(), before)
    }
  }

  const handleBeforeChange = date => {
    if(date && moment(new Date(date)).isValid()) {
      handleChange(after, moment(date).toISOString())
    }
  }

  return (
    <div className="dateRange">
      <DatePicker
        className="afterDate"
        selected={afterDate}
        onChange={date => handleAfterChange(date)}
        selectsStart
        startDate={afterDate}
        endDate={beforeDate}
        minDate={minDate}
        maxDate={new Date()}
        dateFormat="MM/dd/yyyy"
      />
      <span className="afterTime">{afterTime}</span>
      <span className="hyphen">-</span>
      <DatePicker
        className="beforeDate"
        selected={beforeDate}
        onChange={date => handleBeforeChange(date)}
        selectsEnd
        endDate={beforeDate}
        minDate={afterDate}
        maxDate={new Date()}
        dateFormat="MM/dd/yyyy"
        popperClassName="mobilePosition"
      />
      <span className="beforeTime">{beforeTime}</span>
    </div>
  )
}

DateRange.propTypes = {
  before: PropTypes.string.isRequired,
  after: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
}

export default DateRange
