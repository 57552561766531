import React, { useState } from "react"
import PropTypes from "prop-types"

import TextInput from "components/forms/TextInput"
import Button from "components/forms/Button"
import styles from "./Form.module.scss"

const MFAForm = ({ onSubmit, loading, instructions }) => {
  const [mfaToken, setMFAToken] = useState("")

  const handleSubmit = event => {
    event.preventDefault()
    onSubmit({ mfaToken })
  }

  const resendCode = () => {
    onSubmit({ mfaToken: "" })
  }

  return (
    <div className={styles.mfa}>
      <header>2-Step Verification</header>
      <p>{instructions}</p>

      <form onSubmit={handleSubmit}>
        <div className={styles.input}>
          <TextInput
            type="text"
            name="mfaToken"
            label="Enter the code"
            value={mfaToken}
            onChange={e => setMFAToken(e.target.value)}
            disabled={loading}
          />
        </div>

        <div className={styles.actions}>
          <Button label="Next" type="submit" loading={loading} />
        </div>
      </form>

      <div className={styles.resend}>
        <Button
          label="Re-send verification code"
          type="button"
          className="link"
          onClick={resendCode}
        />
      </div>
    </div>
  )
}

MFAForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  instructions: PropTypes.string,
}

MFAForm.defaultProps = {
  instructions:
    "A text message with a 6-digit verification code was just sent to your phone number on record",
}

export default MFAForm
