import React from "react"
import { connect } from "react-redux"
import classNames from "classnames/bind"
import PropTypes from "prop-types"

import DashboardCard from "./Card"
import styles from "./ThreatsByType.module.scss"

const cx = classNames.bind(styles)

const ThreatsByTypeCard = ({ className, threatsByType }) => {
  const rows = threatsByType.map(t => (
    <div className={styles.threat} key={t.id}>
      <span className={styles.name}>{t.name}</span>
      <span className={styles.id}>{t.id}</span>
      <div>{t.threatCount} attempts</div>
    </div>
  ))

  return (
    <DashboardCard
      title="Top Threats by Type"
      className={cx(styles.threatsByType, className)}
    >
      <div className={styles.bodyWrapper}>{rows}</div>
    </DashboardCard>
  )
}

ThreatsByTypeCard.propTypes = {
  className: PropTypes.string,
  threatsByType: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      threatCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
}

ThreatsByTypeCard.defaultProps = {
  className: null,
  threatsByType: [],
}

const mapStateToProps = state => {
  let threatsByType
  if (state.threats.byType) {
    threatsByType = state.threats.byType
  }
  return { threatsByType }
}

export default connect(
  mapStateToProps,
  null
)(ThreatsByTypeCard)
