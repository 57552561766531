import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import get from "lodash/get"
import PropTypes from "prop-types"

import { updateUser as updateU } from "actions"
import Modal from "components/Modal"
import TextInput from "components/forms/TextInput"
import ErrorBox from "components/forms/ErrorBox"
import Button from "components/forms/Button"

import styles from "./ChangePassModal.module.scss"

class ChangePassModal extends React.Component {
  state = {
    password: "",
    verify: "",
    loading: false,
    error: null,
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  handleSubmit = event => {
    const { updateUser, userId, handleClose } = this.props
    const { password, verify } = this.state

    event.preventDefault()

    if (password !== verify) {
      this.setState({ error: "Passwords don't match" })
      return
    }

    this.setState({ loading: true })
    updateUser(userId, { password })
      .then(handleClose)
      .catch(e => {
        console.error("Error updating password", e)
        this.setState({
          loading: false,
          error: get(
            e,
            "data.errors[0].detail",
            "An error occurred, try again."
          ),
        })
      })
  }

  render = () => {
    const { handleClose } = this.props
    const { password, verify, loading, error } = this.state

    return (
      <Modal title="Change Password" handleClose={handleClose}>
        <form className={styles.passwordForm} onSubmit={this.handleSubmit}>
          {error && <ErrorBox message={error} />}

          <TextInput
            name="password"
            type="password"
            label="New Password"
            value={password}
            className={styles.textInput}
            onChange={this.handleChange}
            disabled={loading}
            autoComplete="new-password"
            minLength={10}
            required
          />

          <TextInput
            name="verify"
            type="password"
            label="Confirm New Password"
            value={verify}
            className={styles.textInput}
            onChange={this.handleChange}
            disabled={loading}
            autoComplete="new-password"
            required
          />

          <div className={styles.rules}>
            <ul>
              <li>Must be at least 10 characters</li>
              <li>Must include at least one lower case letter (a-z)</li>
              <li>Must include at least one upper case letter (A-Z)</li>
              <li>Must include at least one number (0-9)</li>
              <li>Cannot be similar to recently used passwords</li>
            </ul>
          </div>

          <Button
            className={styles.button}
            label="Change Password"
            type="submit"
            loading={loading}
          />
        </form>
      </Modal>
    )
  }
}

ChangePassModal.propTypes = {
  userId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  let userId = null

  if (state.auth.user) {
    userId = state.auth.user.id
  }

  return { userId }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ updateUser: updateU }, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePassModal)
