import { serializeCountryThreats } from "serializers/threats"
import * as types from "actions/types"

const defaultState = {
  list: { threats: [], meta: { threatsCount: 0 } },
  listLoading: true,

  polled: { threats: [], meta: { threatsCount: 0 } },

  byCountry: [],
  byCountryLoading: true,

  blocked: [],
  blockedLoading: true,

  byType: [],
  byTypeLoading: true,

  bySource: [],
  bySourceLoading: true,

  byTime: [],
  byTImeLoading: true,

  zoomedCountry: null,
}

const threats = (state = defaultState, action) => {
  switch (action.type) {
    case types.THREATS_LIST:
      return {
        ...state,
        listLoading: false,
        list: action.payload,
      }
    case types.PAGINATE_THREATS_LIST_REQUEST:
      return {
        ...state,
        listLoading: true,
      }
    case types.PAGINATE_THREATS_LIST:
      return {
        ...state,
        listLoading: false,
        list: {
          threats: addListThreats(state, action.payload),
          meta: updateListMeta(state, action.payload),
        },
      }
    case types.THREATS_BY_COUNTRY:
      return {
        ...state,
        byCountryLoading: false,
        byCountry: serializeCountryThreats(action.payload),
      }
    case types.THREATS_BLOCKED_REQUEST:
        return {
          ...state,
          blockedLoading: true,
        }
    case types.THREATS_BLOCKED:
      return {
        ...state,
        blockedLoading: false,
        blocked: action.payload,
      }
    case types.THREATS_BY_TYPE:
      return {
        ...state,
        byTypeLoading: false,
        byType: action.payload,
      }
    case types.THREATS_BY_SOURCE:
      return {
        ...state,
        bySourceLoading: false,
        bySource: action.payload,
      }
    case types.THREATS_BY_TIME:
      return {
        ...state,
        byTimeLoading: false,
        byTime: action.payload,
      }
    case types.SET_POLLED_THREATS:
      return {
        ...state,
        polled: action.payload,
      }
    case types.CLEAR_POLLED_THREATS:
      return {
        ...state,
        polled: { ...state.polled, threats: [] },
      }
    case types.ZOOM_COUNTRY:
      return {
        ...state,
        zoomedCountry: action.payload,
      }
    default:
      return state
  }
}

const addListThreats = (state, payload) => {
  return payload.threats
    ? state.list.threats.concat(payload.threats)
    : state.list.threats
}

const updateListMeta = (state, payload) => {
  return payload.meta ? payload.meta : state.list.meta
}

export default threats
