import React from "react"
import PropTypes from "prop-types"

import { ReactComponent as ErrorIcon } from "assets/images/error-icon.svg"
import styles from "./ErrorBox.module.scss"

const ErrorBox = ({ message }) => (
  <div className={styles.errorBox}>
    <ErrorIcon className={styles.errorIcon} />
    <p>{message}</p>
  </div>
)

ErrorBox.propTypes = {
  message: PropTypes.string.isRequired,
}

export default ErrorBox
