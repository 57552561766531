import React, { useState, useRef, useEffect } from "react"
import classNames from "classnames/bind"
import PropTypes from "prop-types"

import styles from "./DropdownMenu.module.scss"

const cx = classNames.bind(styles)

const DropdownMenu = ({
  renderToggle,
  menuItems,
  alignRight,
  contentClassName,
}) => {
  const node = useRef()
  const [visible, setVisible] = useState(false)

  const handleClickOutside = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return
    }
    // outside click
    setVisible(false)
  }

  useEffect(() => {
    if (visible) {
      document.addEventListener("mousedown", handleClickOutside)
    } else {
      document.removeEventListener("mousedown", handleClickOutside)
    }
    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [visible])

  return (
    <div ref={node} className={styles.container}>
      {renderToggle(visible, setVisible)}
      {visible && (
        <div className={cx(styles.menu, { alignRight }, contentClassName)}>
          {menuItems.map(i => (
            <div
              key={i.text}
              className={styles.menuItem}
              onClick={() => {
                if (i.onClick) i.onClick()
                setVisible(false)
              }}
              onKeyDown={() => setVisible(!visible)}
              role="button"
              tabIndex={0}
            >
              {i.icon && <i className={cx(i.icon, styles.icon)} />}
              {i.text}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

DropdownMenu.propTypes = {
  renderToggle: PropTypes.func.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func,
    })
  ).isRequired,
  alignRight: PropTypes.bool,
  contentClassName: PropTypes.string,
}

DropdownMenu.defaultProps = {
  alignRight: false,
  contentClassName: null,
}

export default DropdownMenu
