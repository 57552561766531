import React from "react"
import Spinner from "react-activity/lib/Spinner"
import classNames from "classnames/bind"
import PropTypes from "prop-types"

import styles from "./Button.module.scss"
import "react-activity/lib/Spinner/Spinner.css"

const cx = classNames.bind(styles)

const Button = ({ type, className, label, onClick, loading }) => (
  <button
    type={type}
    className={cx(styles.button, className)}
    onClick={onClick}
    disabled={loading}
  >
    {loading ? <Spinner size={12.5} color="white" /> : label}
  </button>
)

Button.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
}

Button.defaultProps = {
  type: "button",
  className: null,
  onClick: null,
  loading: false,
}

export default Button
