import axios from "axios"

import { signoutUser } from "actions"
import store from "store"

const API_URL = process.env.REACT_APP_API_URL

const apiHeaders = () => {
  const headers = { "Content-Type": "application/json" }
  const jwt = localStorage.getItem("sessionToken")
  if (jwt) {
    headers["x-whoa-session-id"] = jwt
  }
  return headers
}

const apiCall = (method, path, options = {}) =>
  axios
    .request({
      method,
      url: `${API_URL}/${path}`,
      headers: apiHeaders(),
      params: options.params,
      data: JSON.stringify(options.data),
    })
    .catch(error => {
      if (error.response) {
        if (
          error.response.status === 401 &&
          (path !== "session" && method.toLowerCase() !== "delete")
        ) {
          store.dispatch(signoutUser())
        }
        return Promise.reject(error.response)
      }
      throw error
    })

export default apiCall
