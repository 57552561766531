/* eslint-disable prefer-destructuring */
import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import ReactResizeDetector from "react-resize-detector"
import * as d3 from "d3"
import d3Tip from "d3-tip"
import "d3-geo-projection"
import * as topojson from "topojson-client"
import { isEqual } from "lodash"
import classNames from "classnames"
import PropTypes from "prop-types"

import ThreatsLegend from "components/dashboard/ThreatsLegend"
import {
  clearPollThreats as clearThreats,
  zoomCountry as zoom,
  getThreatDestinations as getDests,
} from "actions"
import countryCenters from "utils/countryCenters"
import marker from "assets/images/map-marker.svg"
import { ReactComponent as CloseButton } from "assets/images/close-button.svg"
import "./ThreatsMap.scss"

// this a point in the northwest corner of the continental USA
// used for including the US in zooming
const USA_NORTHWEST_CORNER = [-124.726987, 48.386133]
const USA_SOUTHEAST_CORNER = [-79.427489, 24.571449]
const FRA_SOUTHEAST_CORNER = [7.495806, 42.233132]

class ThreatsMap extends React.Component {
  constructor(props) {
    super(props)
    this.container = React.createRef()
    this.projection = null
    this.path = null
    this.svg = null
    this.g = null
    this.countryTip = null
    this.markerDimension = 20
  }

  componentDidUpdate = prevProps => {
    const { threatsByCountry, polledThreats } = this.props
    if (
      threatsByCountry.length &&
      !isEqual(prevProps.threatsByCountry, threatsByCountry)
    ) {
      if (this.svg) {
        this.drawAllCountries()
      } else {
        this.draw()
      }
    }
    if (polledThreats.length) {
      this.handleRocketLaunch()
    }
  }

  handleResize = () => {
    console.log("resize")
    if (this.svg) {
      const container = this.container.current
      const mapRatio = container.offsetHeight / container.offsetWidth
      const widthDivisor = 2
      const heightDivisor = 2
      const width = parseInt(
        d3.select("#threatMapContainer").style("width"),
        10
      )
      const height = width * mapRatio

      // update projection
      this.projection
        .translate([width / widthDivisor, height / heightDivisor])
        .scale((width / 680) * 100)

      // resize the map container
      this.svg.attr("width", width).attr("height", height)

      // redraw the map
      this.svg.selectAll(".country").attr("d", this.path)

      // move the text counts
      this.svg
        .selectAll(".threat-count")
        .attr("transform", d => `translate(${this.getCountryCenter(d)})`)

      // move the datacenter tooltips
      this.svg
        .selectAll(".map-marker")
        .attr(
          "transform",
          d => `translate(${this.datacenter(d.longitude, d.latitude)})`
        )
    }
  }

  handleRocketLaunch = () => {
    console.log("rocket launched")
    const {
      threatsByCountry,
      polledThreats,
      destinations,
      clearPollThreats,
    } = this.props

    polledThreats.forEach(threat => {
      const countryCode = threat.source.country.code
      const { severity, destination } = threat

      // get the country element
      const country = this.g.select("#countries").select(`.${countryCode}`)

      const countryData = threatsByCountry.find(
        ct => ct.countryCode === countryCode
      )

      const target = destinations.find(d => d.id === destination.location.id)

      this.plotLine(target, countryData)

      const route = this.g.selectAll(`path.route.${countryCode}`)

      route.classed(`pulsing ${severity}`, true)
      country.classed("pulsing", true)
    })

    clearPollThreats()

    // remove all pulsing classes from countries and routes after the pulsing is completed
    setTimeout(() => {
      this.g.selectAll(".country, .route").classed("pulsing", false)
    }, 10000)
  }

  plotLine = (datacenter, countryData) => {
    const { zoomedCountry } = this.props
    const source = this.getCountryCenter(countryData)

    const destination = this.projection([
      datacenter.longitude,
      datacenter.latitude,
    ])

    const dx = source[0] - destination[0]
    const dy = source[1] - destination[1]
    const dr = Math.sqrt(dx * dx + dy * dy)
    const arc = `M ${destination[0]},${destination[1]}A${dr},${dr} 0 0,1 ${
      source[0]
    },${source[1]}`

    const { countryCode, severity } = countryData

    if (zoomedCountry && zoomedCountry.id === countryCode) {
      this.g
        .insert("path", "text:first-of-type")
        .attr("class", `route ${countryCode}`)
        .attr("d", arc)
    } else {
      const blip = this.svg
        .append("circle")
        .attr("class", `threat-blip ${severity}`)
        .attr("cx", 0)
        .attr("cy", 0)
        .attr("r", 15)

      const route = this.g
        .insert("path", "text:first-of-type")
        .attr("class", `route ${countryCode}`)
        .attr("d", arc)
        .attr("active", false)

      this.transitionRoute(blip, route)
    }
  }

  transitionRoute = (blip, route) => {
    const length = route.node().getTotalLength()
    blip
      .transition()
      .duration(length * 9)
      .attrTween("transform", this.delta(route.node()))
      .on("end", () => {
        route.remove()
      })
      .remove()
  }

  delta = path => {
    const length = path.getTotalLength()
    return () => {
      return t => {
        const inverseT = 1 - t
        const p = path.getPointAtLength(inverseT * length)
        const s = Math.min(Math.sin(Math.PI * inverseT) * 0.7, 0.3)

        return `translate(${p.x},${p.y}) scale(${s})`
      }
    }
  }

  drawAllCountries = () => {
    console.log("drawing countries")
    const { threatsByCountry, destinations } = this.props

    // remove existing threat count text if any
    d3.selectAll(".threat-count").remove()
    this.g
      .selectAll("text")
      .data(threatsByCountry)
      .enter()
      .append("text")
      .attr("class", d => `threat-count ${d.countryCode}`)
      .attr("transform", d => `translate(${this.getCountryCenter(d)})`)
      .text(d => d.totalCount)

    // remove existing tooltips
    d3.selectAll(".threats-map-tooltip").remove()

    const countryTip = d3Tip()
      .attr("class", "threats-map-tooltip")
      .offset(this.getCountryTipOffset)
      .html(this.countryTooltip)

    this.countryTip = countryTip

    countryTip.direction(d => {
      const topCountries = [
        "USA",
        "RUS",
        "CAN",
        "NOR",
        "SWE",
        "FIN",
        "GRL",
        "ISL",
      ]
      return topCountries.includes(d.id) ? "s" : "n"
    })

    this.svg.call(countryTip)

    const datacenterTip = d3Tip()
      .attr("class", "threats-map-tooltip")
      .offset([-10, 0])
      .html(this.datacenterTooltip)
    this.datacenterTip = datacenterTip

    this.svg.call(datacenterTip)

    d3.selectAll(".map-marker").remove()
    this.g
      .selectAll(".map-marker")
      .data(destinations)
      .enter()
      .append("image")
      .attr("class", "map-marker")
      .attr("width", this.markerDimension)
      .attr("height", this.markerDimension)
      .attr("xlink:href", marker)
      .attr(
        "transform",
        d => `translate(${this.datacenter(d.longitude, d.latitude)})`
      )
      .on("mouseover", this.datacenterTip.show)
      .on("mouseout", this.datacenterTip.hide)

    // remove existing threat count text if any
    d3.selectAll(".country").classed(
      "critical high medium low clickable",
      false
    )

    // remove event listener from country tooltips
    d3.selectAll(".country").on("mouseover", null)
    d3.selectAll(".country").on("mouseout", null)

    threatsByCountry.forEach(({ countryCode, severity }) => {
      const element = this.g.select("#countries").select(`.${countryCode}`)

      if (element.node() !== null) {
        element.classed(severity, true)
        element.classed("clickable", true)

        element
          .on("mouseover", this.countryTip.show)
          .on("mouseout", this.countryTip.hide)
      } else {
        console.log(
          `${countryCode} was not found on the map. Not mapping threat.`
        )
      }
    })
  }

  getCountryCenter = country => {
    const { countryCode, totalCount } = country
    const element = this.g.select("#countries").select(`.${countryCode}`)
    if (element.node() !== null) {
      let coords
      if (countryCenters.has(countryCode)) {
        // hard-coded coords
        const [lat, lon] = countryCenters.get(countryCode)
        coords = this.projection([lon, lat])
      } else {
        // fall back on bounding box center
        const bbox = element.node().getBBox()
        coords = [bbox.x + bbox.width / 2, bbox.y + bbox.height / 2]
      }
      // offset the coords depending on the width of count text
      coords[0] -= (totalCount.toString().length * 4) / 2
      return coords
    }
    return 0
  }

  getCountryTipOffset = country => (country.id === "USA" ? [-23, -380] : [0, 0])

  countryTooltip = country => {
    const { threatsByCountry } = this.props
    const countryCode = country.id
    const countryName = country.properties.name
    const countryData = threatsByCountry.find(
      ct => ct.countryCode === countryCode
    )

    return `
      <div class="country-tooltip">
        <div class="country-tooltip-header">
          <div class="country">
            <span class="country-flag ${countryCode.toLowerCase()}"></span>
            <span class="h5">${countryName}</span>
          </div>
          <div class="severity-circle ${countryData.severity}"></div>
        </div>
        <div class="country-tooltip-body">
          <div class="country-tooltip-total">
            <span class="h6">Threats Blocked</span>
            <div class="threats-total">${countryData.totalCount}</div>
          </div>
          <table class="threats-blocked">
            <tbody>
              <tr>
                <th><span class="threat-severity-block critical"></span>Critical</th><td>${
                  countryData.critCount
                }</td><td><span class="percent">${
      countryData.critPercent
    }%</span></td>
              </tr>
              <tr>
                <th><span class="threat-severity-block high"></span>High</th><td>${
                  countryData.highCount
                }</td><td><span class="percent">${
      countryData.highPercent
    }%</span></td>
              </tr>
              <tr>
                <th><span class="threat-severity-block medium"></span>Medium</th><td>${
                  countryData.midCount
                }</td><td><span class="percent">${
      countryData.midPercent
    }%</span></td>
              </tr>
              <tr>
                <th><span class="threat-severity-block low"></span>Low</th><td>${
                  countryData.lowCount
                }</td><td><span class="percent">${
      countryData.lowPercent
    }%</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    `
  }

  datacenterTooltip = datacenter => {
    const { provider } = this.props
    return `
      <div class="datacenter-tooltip">
        <div class="datacenter-tooltip-header">
          <span class="h5">${datacenter.name}</span>
          ${
            provider.image
              ? `<div class="logo">
                <img src="${provider.image}" alt="logo" />
              </div>`
              : ""
          }
      </div>
        <div class="image">
          <img src="${datacenter.image}" />
        </div>
        <p class="provider">${datacenter.provider}</p>
        <p class="description">${datacenter.description}</p>
      </div>
    `
  }

  datacenter = (lon, lat) => {
    const p = this.getCoordinates(lon, lat)

    // compensate for image size
    p[0] -= this.markerDimension / 2 // width
    p[1] -= this.markerDimension - 2 // height
    return p
  }

  getCoordinates = (lon, lat) => {
    return this.projection([lon, lat])
  }

  getXYZ = country => {
    const container = this.container.current
    const height = container.offsetHeight
    const width = container.offsetWidth

    // [x, y] of the northwest corner of the continental US
    const USANorthwestCorner = this.projection(USA_NORTHWEST_CORNER)
    const bounds = this.path.bounds(country)

    let otherSoutheastX = bounds[1][0]
    let otherSoutheastY = bounds[1][1]

    // adjustments for France
    if (country.id === "FRA") {
      const FRASoutheastCorner = this.projection(FRA_SOUTHEAST_CORNER)
      otherSoutheastY = FRASoutheastCorner[1]
    }

    // if the lower y of the country is higher than the US northwest corner y, use the southeast corner of the US
    // if(bounds[1][1] < usa_nw_corner[1]) {
    if (country.id === "USA") {
      const USASoutheastCorner = this.projection(USA_SOUTHEAST_CORNER)
      otherSoutheastX = USASoutheastCorner[0]
      otherSoutheastY = USASoutheastCorner[1]
    }

    const widthScale = (otherSoutheastX - USANorthwestCorner[0]) / width
    const heightScale = (otherSoutheastY - USANorthwestCorner[1]) / height

    const z = 0.75 / Math.max(widthScale, heightScale)
    const x = (otherSoutheastX + USANorthwestCorner[0]) / 2
    const y = (otherSoutheastY + USANorthwestCorner[1]) / 2 + height / z / 10
    return [x, y, z]
  }

  zoomIn = country => {
    const { zoomCountry } = this.props

    zoomCountry(country)

    // hide popovers and remove lines immediately - the callback waits for the zoom to finish
    this.removeThreatLines()
    this.hidePopovers(country)

    const usa = this.g.select("#countries").select(".USA")
    usa.classed("fade-severity", true)

    const xyz = this.getXYZ(country)
    this.zoom(xyz, "in", country)
  }

  zoomOut = () => {
    const { zoomCountry, zoomedCountry } = this.props
    const container = this.container.current
    const width = container.offsetWidth
    const height = container.offsetHeight
    const widthDivisor = 2
    const heightDivisor = 2

    zoomCountry(null)

    // remove lines immediately - the callback waits for the zoom to finish
    this.removeThreatLines()

    const usa = this.g.select("#countries").select(".USA")
    usa.classed("fade-severity", false)

    const xyz = [width / widthDivisor, height / heightDivisor, 1]
    this.zoom(xyz, "out", zoomedCountry)
  }

  zoom = (xyz, direction, country) => {
    const duration = 750
    this.g
      .transition()
      .duration(duration)
      .attr(
        "transform",
        `translate(${this.projection.translate()})scale(${xyz[2]})translate(-${
          xyz[0]
        },-${xyz[1]})`
      )
      .selectAll(["#countries", "#states", "#cities"])
      .style("stroke-width", `${1.0 / xyz[2]} px`)
      .selectAll(".city")
      .attr("d", this.path.pointRadius(20.0 / xyz[2]))

    // wait til the animation completes before
    setTimeout(() => {
      if (direction === "out") {
        this.afterZoomOut(country)
      } else {
        this.afterZoomIn()
      }
    }, duration)
  }

  afterZoomIn = () => {
    const { zoomedCountry } = this.props
    this.drawThreatLines(zoomedCountry)
  }

  afterZoomOut = country => {
    this.showPopovers(country)
  }

  hidePopovers = country => {
    const element = this.g.select("#countries").select(`.${country.id}`)
    element.on("mouseover", null).on("mouseout", null)
  }

  enablePopovers = country => {
    const element = this.g.select("#countries").select(`.${country.id}`)
    element
      .on("mouseover", this.countryTip.show)
      .on("mouseout", this.countryTip.hide)
  }

  showPopovers = country => {
    this.enablePopovers(country)
  }

  removeThreatLines = () => {
    const routes = d3.selectAll(".route")
    routes.remove()
  }

  drawThreatLines = d => {
    const { threatsByCountry, getThreatDestinations } = this.props
    const countryCode = d.id
    const countryData = threatsByCountry.find(
      ct => ct.countryCode === countryCode
    )

    // get a list of datacenters that go with this country's threats
    getThreatDestinations(countryCode).then(res => {
      res.data.forEach(destination => {
        this.plotLine(destination, countryData)
      })
    })
  }

  countryClicked = country => {
    const { threatsByCountry, zoomedCountry } = this.props
    const countryCode = country.id
    const countryData = threatsByCountry.find(
      ct => ct.countryCode === countryCode
    )

    // only do something if this country has threats
    if (countryData) {
      if (country && zoomedCountry !== country) {
        // enable popovers for a zoomed in country after clicking on another country
        if (zoomedCountry !== null) {
          // this._enablePopovers(this.get("country"))
        }
        this.zoomIn(country)
      } else {
        this.zoomOut()
      }
    }
  }

  draw = () => {
    console.log("drawing map")
    const container = this.container.current
    const mapRatio = container.offsetHeight / container.offsetWidth
    const widthDivisor = 2
    const heightDivisor = 2
    const width = parseInt(d3.select("#threatMapContainer").style("width"), 0)
    const height = width * mapRatio

    const existing = d3.select("#threatMapContainer").selectAll("svg")
    if (existing) {
      d3.select(".threats-map-tooltip").remove()
      existing.remove()
    }

    const svg = d3
      .select(container)
      .append("svg")
      .attr("class", "map")
      .attr("preserveAspectRatio", "xMidYMid slice")
      .attr("width", width)
      .attr("height", height)
    this.svg = svg

    const projection = d3
      .geoEquirectangular()
      .rotate([-11, 0])
      .scale((width / 680) * 100)
      .translate([width / widthDivisor, height / heightDivisor])
    this.projection = projection

    const path = d3.geoPath().projection(projection)
    this.path = path

    const g = svg.append("g")
    this.g = g

    // eslint-disable-next-line consistent-return
    d3.json(`${process.env.PUBLIC_URL}/countries.topo.json`)
      .then(world => {
        const countries = topojson.feature(world, world.objects.units)

        g.append("g")
          .attr("id", "countries")
          .selectAll("path")
          .data(countries.features)
          .enter()
          .append("path")
          .attr("class", d => `country ${d.id}`)
          .attr("d", this.path)
          .on("click", this.countryClicked)

        this.drawAllCountries()
      })
      .catch(error => console.error(error))
  }

  render = () => {
    const { className, hidden, zoomedCountry } = this.props

    return (
      <div
        ref={this.container}
        className={classNames("threatMapContainer", className)}
        id="threatMapContainer"
      >
        {!hidden && <ThreatsLegend />}
        {zoomedCountry && (
          <button className="zoomClose" onClick={() => this.zoomOut()}>
            <CloseButton />
          </button>
        )}
        <ReactResizeDetector
          handleWidth
          handleHeight
          onResize={this.handleResize}
        />
      </div>
    )
  }
}

ThreatsMap.propTypes = {
  className: PropTypes.string,
  hidden: PropTypes.bool.isRequired,
  threatsByCountry: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      id: PropTypes.string,
      latitude: PropTypes.string,
      longitude: PropTypes.string,
      name: PropTypes.string,
      threatCounts: PropTypes.object,
    })
  ).isRequired,
  destinations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      provider: PropTypes.string,
      image: PropTypes.string,
      description: PropTypes.string,
      latitude: PropTypes.string,
      longitude: PropTypes.string,
    })
  ).isRequired,
  polledThreats: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      destination: PropTypes.object,
    })
  ).isRequired,
  provider: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
  clearPollThreats: PropTypes.func.isRequired,
  zoomedCountry: PropTypes.shape({
    id: PropTypes.string,
  }),
  zoomCountry: PropTypes.func.isRequired,
  getThreatDestinations: PropTypes.func.isRequired,
}

ThreatsMap.defaultProps = {
  className: null,
  zoomedCountry: null,
}
const mapStateToProps = state => {
  const { threats, destinations } = state
  const threatsByCountry = threats.byCountry
  const polledThreats = threats.polled.threats
  const { zoomedCountry } = threats
  return { threatsByCountry, destinations, polledThreats, zoomedCountry }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        clearPollThreats: clearThreats,
        zoomCountry: zoom,
        getThreatDestinations: getDests,
      },
      dispatch
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThreatsMap)
