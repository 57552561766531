import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PropTypes from "prop-types"

import { forgotPassword as forgot } from "actions"
import ForgotPasswordForm from "components/forgotPassword/Form"
import styles from "./Login.module.scss"

// eslint-disable-next-line import/exports-last
export class ForgotPasswordRoute extends React.Component {
  state = {
    loading: false,
    submitted: false,
  }

  onSubmit = email => {
    const { forgotPassword } = this.props

    this.setState({ loading: true })
    forgotPassword(email)
      .then(() => {
        this.setState({
          loading: false,
          submitted: true,
        })
      })
      .catch(e => {
        // shouldn't get here
        console.error("Unknown error", e)
      })
  }

  onCancel = () => {
    const { history } = this.props
    history.push("/login")
  }

  render = () => {
    const { loading, submitted } = this.state
    return (
      <div className={styles.loginPage}>
        <div className={styles.modalWrapper}>
          <ForgotPasswordForm
            onSubmit={this.onSubmit}
            onCancel={this.onCancel}
            loading={loading}
            submitted={submitted}
          />
        </div>
      </div>
    )
  }
}

ForgotPasswordRoute.propTypes = {
  forgotPassword: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ forgotPassword: forgot }, dispatch),
  }
}

export default connect(
  null,
  mapDispatchToProps
)(ForgotPasswordRoute)
