import * as types from "actions/types"

const defaultState = {
  authenticated: false,
  accounts: [],
  account: null,
  user: null,
}

const auth = (state = defaultState, action) => {
  switch (action.type) {
    case types.SIGNIN_SUCCESS:
      return {
        ...state,
        authenticated: true,
        account: action.payload.account,
        provider: action.payload.provider,
        user: action.payload.user,
      }
    case types.SIGNOUT_SUCCESS:
      return { authenticated: false }
    case types.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
      }
    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
      }
    case types.GET_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload,
      }
    default:
      return state
  }
}

export default auth
