import React from "react"
import classNames from "classnames/bind"
import PropTypes from "prop-types"

import styles from "./Card.module.scss"

const cx = classNames.bind(styles)

/**
 * @prop header - takes precedence over `title` and `centeredTitle`
 * @prop centeredTitle - takes precedence over `title`
 */
const DashboardCard = ({ header, title, centered, className, children }) => (
  <div className={cx(styles.dashboardCard, className)}>
    {header || (
      <header className={cx({ centered })}>
        <h3>{title}</h3>
      </header>
    )}
    <div className={styles.cardBody}>{children}</div>
  </div>
)

DashboardCard.propTypes = {
  header: PropTypes.node,
  centered: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

DashboardCard.defaultProps = {
  header: null,
  centered: false,
  title: null,
  className: null,
}

export default DashboardCard
