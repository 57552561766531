import * as types from "actions/types"
import moment from "moment"

const DATE_FORMAT = "Y-MM-DD"

const getAfter = text => {
  const [number] = text.match(/\d+/)
  const pattern = new RegExp("hours")
  const days = pattern.test(text.toLowerCase()) ? number / 24 : number
  return moment()
    .subtract(days, "days")
    .toISOString()
}

const getBefore = () => moment().toISOString()

const getIsLive = before => moment().format(DATE_FORMAT) === before

const getText = (after, before) => {
  if (getIsLive(before)) {
    const mAfter = moment(after)
    const mBefore = moment(before)
    const daysDiff = mBefore.diff(mAfter, "days") + 1
    if ([1, 2, 3, 7, 30, 90].includes(daysDiff)) {
      return daysDiff <= 3
        ? `Past ${daysDiff * 24} Hours`
        : `Past ${daysDiff} Days`
    }
  }
  return "custom"
}

const defaultState = {
  text: "Past 7 Days",
  after: getAfter("Past 7 Days"),
  before: getBefore(),
  isLive: true,
  lastPoll: moment().toISOString(),
}

const timeframe = (state = defaultState, action) => {
  const currentTime = getBefore()

  switch (action.type) {
    case types.SELECT_TIMEFRAME_OPTION:
      return {
        ...state,
        text: action.payload,
        after: getAfter(action.payload),
        before: currentTime,
        isLive: true,
      }
    case types.CHANGE_TIMEFRAME_CUSTOM:
      return {
        ...state,
        text: getText(action.after, action.before),
        after: action.after,
        before: action.before,
        isLive: getIsLive(action.before),
      }
    case types.SET_POLLING_TIMEFRAME:
      return {
        ...state,
        before: action.payload,
        after: getAfter(state.text),
        lastPoll: currentTime,
      }
    default:
      return state
  }
}

export default timeframe
