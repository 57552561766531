import React, { useState } from "react"
import PropTypes from "prop-types"

import TextInput from "components/forms/TextInput"
import Button from "components/forms/Button"
import styles from "./Form.module.scss"

const ForgotPasswordForm = ({ onSubmit, onCancel, loading, submitted }) => {
  const [email, setEmail] = useState("")

  const handleSubmit = event => {
    event.preventDefault()
    onSubmit(email)
  }

  return (
    <div className={styles.forgotPassword}>
      <header>Forgot Your Password?</header>

      {submitted ? (
        <div className={styles.successBox}>
          <p>
            An email with a reset password link has been sent to your address.
            If you don’t see the email, check your spam folder!
          </p>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className={styles.input}>
            <TextInput
              type="email"
              name="forgotPassEmail"
              label="Email Address"
              value={email}
              onChange={e => setEmail(e.target.value)}
              disabled={loading}
            />
          </div>

          <div className={styles.actions}>
            <Button
              label="Cancel"
              type="button"
              className="cancel"
              onClick={onCancel}
            />
            <Button
              label="Email Password Reset"
              type="submit"
              loading={loading}
            />
          </div>
        </form>
      )}
    </div>
  )
}

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  submitted: PropTypes.bool.isRequired,
}

export default ForgotPasswordForm
