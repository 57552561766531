import apiCall from "utils/api"
import * as types from "./types"

const sendReport = () => (dispatch, getState) => {
  const { after, before } = getState().timeframe
  return apiCall("post", "reports", {
    params: { after, before },
  }).then(() => dispatch({ type: types.REPORT_SENT }))
}

// eslint-disable-next-line import/prefer-default-export
export { sendReport }
