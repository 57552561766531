import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"

import styles from "./CircleButton.module.scss"

const cx = classNames.bind(styles)

const CircleButton = ({ children, className, onClick }) => (
  <button className={cx(styles.circleButton, className)} onClick={onClick}>
    {children}
  </button>
)

CircleButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

CircleButton.defaultProps = {
  className: ""
}

export default CircleButton
