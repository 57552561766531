import React from "react"
import ReactResizeDetector from "react-resize-detector"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as d3 from "d3"
import { isEqual } from "lodash"
import classNames from "classnames"
import PropTypes from "prop-types"

import {
  getThreatsByTime as getByTime,
  changeTimeFrameCustom as changeTimeFrame,
} from "actions"
import "./ThreatsByTime.scss"
import moment from "moment"

const DATE_FORMAT = "Y-MM-DD"

class ThreatsByTime extends React.Component {
  constructor(props) {
    super(props)
    this.container = React.createRef()
    this.brush = null
    this.brushContext = null
  }

  componentDidMount = () => {
    const { getThreatsByTime } = this.props
    getThreatsByTime()
  }

  componentDidUpdate = prevProps => {
    const { threatsByTime, timeframe } = this.props
    if (
      threatsByTime.length > 0 &&
      !isEqual(prevProps.threatsByTime, threatsByTime)
    ) {
      this.draw()
    }
    if (
      prevProps.timeframe.before !== timeframe.before ||
      prevProps.timeframe.after !== timeframe.after
    ) {
      this.moveBrush()
    }
  }

  handleResize = () => {
    const { threatsByTime } = this.props
    if (threatsByTime.length > 0) {
      this.draw()
    }
  }

  handleTimeFrameChange = (after, before) => {
    const { changeTimeFrameCustom } = this.props
    changeTimeFrameCustom(after, before)
  }

  formatTick = (d, i) => {
    return i % 8 === 0 ? moment(d).format("MMM D") : ""
  }

  moveBrush = () => {
    const { timeframe } = this.props
    const d0 = moment(timeframe.after).format(DATE_FORMAT)
    const d1 = moment(timeframe.before).format(DATE_FORMAT)
    d3.select(".brush")
      .transition()
      .call(this.brush.move, d1 >= d0 ? this.getDerviedRange([d0, d1]) : null)
  }

  getDerviedRange = dates => {
    const eachBand = this.xScale.step()
    const x0 = this.xScale(dates[0])
    // move brush to end of bar
    const x1 = this.xScale(dates[1]) + eachBand - 1
    return [x0, x1]
  }

  draw = () => {
    const { timeframe, threatsByTime } = this.props
    const container = this.container.current

    const MARGIN_TOP = container.offsetWidth >= 480 ? 10 : 2 
    const MARGIN_RIGHT = 0
    const MARGIN_BOTTOM = 1
    const MARGIN_LEFT = 0

    const existing = d3.select(container).selectAll("svg")
    if (existing) {
      existing.remove()
    }

    const afterDate = moment(timeframe.after).format(DATE_FORMAT)
    const beforeDate = moment(timeframe.before).format(DATE_FORMAT)

    const width =
      parseInt(d3.select(container).style("width"), 10) -
      MARGIN_LEFT -
      MARGIN_RIGHT
    const height =
      parseInt(d3.select(container).style("height"), 10) -
      MARGIN_TOP -
      MARGIN_BOTTOM

    const xScale = d3
      .scaleBand()
      .range([0, width])
      .domain(threatsByTime.map(d => d.date))
      .paddingInner(0.04)
    this.xScale = xScale

    const yScale = d3
      .scaleLinear()
      .rangeRound([height, 15])
      .domain([0, d3.max(threatsByTime, d => d.threatCount)])

    const xAxis = d3
      .axisTop(xScale)
      .tickSize(0)
      .tickPadding(5)
      .tickFormat(this.formatTick)

    const svg = d3
      .select(container)
      .append("svg")
      .attr("width", width + MARGIN_LEFT + MARGIN_RIGHT)
      .attr("height", height + MARGIN_TOP + MARGIN_BOTTOM)
      .append("g")
      .attr("transform", `translate(${MARGIN_LEFT}, ${MARGIN_TOP})`)

    const bar = svg
      .selectAll(".bar")
      .data(threatsByTime)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", d => xScale(d.date))
      .attr("width", xScale.bandwidth())
      .attr("y", d => yScale(d.threatCount))
      .attr("height", d => height - yScale(d.threatCount))

    const scaleBandInvert = scale => {
      const domain = scale.domain()
      const paddingOuter = scale(domain[0])
      const eachBand = scale.step()
      this.eachBand = eachBand
      return value => {
        const index = Math.floor((value - paddingOuter) / eachBand)
        return domain[Math.max(0, Math.min(index, domain.length - 1))]
      }
    }

    const brushed = () => {
      const { selection } = d3.event
      if (!selection) return
      const sx = selection.map(scaleBandInvert(xScale))
      bar.classed("covered", d => sx[0] <= d.date && d.date <= sx[1])
    }

    const brushEnded = () => {
      const { selection, sourceEvent } = d3.event
      if (!sourceEvent || !selection) return
      const [d0, d1] = selection.map(scaleBandInvert(xScale))
      this.handleTimeFrameChange(d0, d1)
      d3.select(".brush")
        .transition()
        .call(this.brush.move, d1 >= d0 ? this.getDerviedRange([d0, d1]) : null)
    }

    const brush = d3
      .brushX()
      .extent([[0, 0], [width, height]])
      .on("start brush end", brushed)
      .on("end.snap", brushEnded)
    this.brush = brush

    const context = svg
      .append("g")
      .attr("class", "context")
      .attr("transform", `translate(${MARGIN_LEFT}, ${MARGIN_TOP})`)

    context
      .append("g")
      .attr("class", "axis axis--x")
      .call(xAxis)

    this.brushContext = context
      .append("g")
      .attr("class", "brush")
      .call(brush)
      .call(brush.move, this.getDerviedRange([afterDate, beforeDate]))
      .selectAll("rect")
      .attr("rx", 4)
  }

  render = () => {
    const { className } = this.props

    return (
      <div
        ref={this.container}
        className={classNames("threatTimeLine", className)}
      >
        <ReactResizeDetector
          handleWidth
          handleHeight
          onResize={this.handleResize}
        />
      </div>
    )
  }
}

ThreatsByTime.propTypes = {
  className: PropTypes.string,
  getThreatsByTime: PropTypes.func.isRequired,
  changeTimeFrameCustom: PropTypes.func.isRequired,
  timeframe: PropTypes.shape({
    text: PropTypes.string.isRequired,
    after: PropTypes.string.isRequired,
    before: PropTypes.string.isRequired,
  }).isRequired,
  threatsByTime: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      threatCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
}

ThreatsByTime.defaultProps = {
  className: null,
  threatsByTime: [],
}

const mapStateToProps = state => ({
  timeframe: state.timeframe,
  threatsByTime: state.threats.byTime,
})

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      { getThreatsByTime: getByTime, changeTimeFrameCustom: changeTimeFrame },
      dispatch
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThreatsByTime)
