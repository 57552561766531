import React, { useState } from "react"
import classNames from "classnames/bind"
import PropTypes from "prop-types"
import ThreatsMap from "components/dashboard/ThreatsMap"
import ThreatsLog from "components/dashboard/ThreatsLog"
import styles from "./ThreatsPanel.module.scss"

const cx = classNames.bind(styles)

const ThreatsPanel = ({ provider }) => {
  const [logMaximized, maximizeLog] = useState(false)
  const [logMinimized, minimizeLog] = useState(false)

  return (
    <div className={styles.panel}>
      <ThreatsMap
        className={cx(styles.map, { logMaximized, logMinimized })}
        hidden={logMaximized}
        provider={provider}
      />
      <ThreatsLog
        className={cx(styles.log, { logMinimized })}
        maximized={logMaximized}
        minimized={logMinimized}
        maximize={() => maximizeLog(true)}
        minimize={() => minimizeLog(true)}
        contract={() => maximizeLog(false)}
        expand={() => minimizeLog(false)}
      />
    </div>
  )
}

ThreatsPanel.propTypes = {
  provider: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
}

export default ThreatsPanel
