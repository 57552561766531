import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"

import TextInput from "components/forms/TextInput"
import ErrorBox from "components/forms/ErrorBox"
import Button from "components/forms/Button"
import styles from "./Form.module.scss"

const LoginForm = ({
  email,
  password,
  onChange,
  onLogin,
  loading,
  errorMsg,
}) => {
  const handleSubmit = event => {
    event.preventDefault()
    onLogin()
  }

  return (
    <form className={styles.loginForm} onSubmit={handleSubmit}>
      <header>Log In</header>

      {errorMsg && <ErrorBox message={errorMsg} />}

      <div className={styles.input}>
        <TextInput
          type="email"
          name="email"
          label="Email Address"
          value={email}
          onChange={onChange}
          disabled={loading}
        />
      </div>

      <div className={styles.input}>
        <TextInput
          type="password"
          name="password"
          label="Password"
          value={password}
          onChange={onChange}
          disabled={loading}
        />
      </div>

      <Button
        label="Log In"
        type="submit"
        className={styles.button}
        loading={loading}
      />

      <Link to="/forgot-password" className={styles.forgotPass}>
        Forgot Password?
      </Link>
    </form>
  )
}

LoginForm.propTypes = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  errorMsg: PropTypes.string,
}

LoginForm.defaultProps = {
  errorMsg: null,
}

export default LoginForm
