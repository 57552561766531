import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import classNames from "classnames/bind"
import PropTypes from "prop-types"
import MediaQuery from "react-responsive"
import isWindows from "utils/platform"

import { paginateThreats as getNext, sendReport as sendRep } from "actions"
import { ReactComponent as Email } from "assets/images/email.svg"
import { ReactComponent as UpArrow } from "assets/images/up-arrow.svg"
import { ReactComponent as DownArrow } from "assets/images/down-arrow.svg"
import ThreatsRow from "components/dashboard/ThreatsRow"
import CountryFlag from "components/CountryFlag"
import SendReportModal from "components/dashboard/SendReportModal"
import Button from "components/forms/Button"
import CircleButton from "components/forms/CircleButton"
import DashboardCard from "./Card"


import styles from "./ThreatsLog.module.scss"

const cx = classNames.bind(styles)
const SCROLL_TRIGGER = 200

class ThreatsLog extends React.Component {
  state = {
    offset: 0,
    limit: 50,
    scrollBars: isWindows(),
    isReportModalOpen: false,
  }

  componentDidUpdate = prevProps => {
    const { threatsCount } = this.props
    if (threatsCount !== prevProps.threatsCount) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ offset: 0 })
    }
  }

  fetchThreats = () => {
    const { paginateThreats } = this.props
    const { offset, limit } = this.state
    paginateThreats(offset, limit)
  }

  handleSendReport = () => {
    const { sendReport } = this.props
    sendReport()
  }

  handleNextPage = () => {
    const { threatsList, threatsCount } = this.props
    if (threatsList.length < threatsCount) {
      const { offset, limit } = this.state
      this.setState({ offset: offset + limit }, () => this.fetchThreats())
    }
  }

  handleScroll = event => {
    const body = event.target
    if (
      body.scrollTop + body.offsetHeight >=
      body.scrollHeight - SCROLL_TRIGGER
    ) {
      this.handleNextPage()
    }
  }

  render = () => {
    const {
      className,
      threatsList,
      listLoading,
      threatsCount,
      maximized,
      maximize,
      minimized,
      minimize,
      contract,
      expand,
      zoomedCountry,
    } = this.props
    const { scrollBars, isReportModalOpen } = this.state

    const rows = threatsList.map(t => (
      <ThreatsRow key={t.id} threat={t} showFlag={!zoomedCountry} />
    ))

    const getTitle = () => {
      if (zoomedCountry) {
        return (
          <>
            Threats from {zoomedCountry.properties.name} ({threatsCount})
            <CountryFlag
              code={zoomedCountry.id}
              className={styles.headerFlag}
            />
          </>
        )
      }
      return `All Global Threats (${threatsCount})`
    }

    return (
      <>
        <DashboardCard
          header={
            <header>
              <h3>{getTitle()}</h3>
              <div className={styles.actions}>
                <CircleButton
                  onClick={() => this.setState({ isReportModalOpen: true })}
                >
                  <Email />
                </CircleButton>
                {maximized && (
                  <CircleButton onClick={contract} className={styles.arrow}>
                    <DownArrow />
                  </CircleButton>
                )}
                {!maximized && !minimized && (
                  <CircleButton onClick={minimize} className={styles.arrow}>
                    <DownArrow />
                  </CircleButton>
                )}
                {minimized && (
                  <CircleButton onClick={expand} className={styles.arrow}>
                    <UpArrow />
                  </CircleButton>
                )}
                {!maximized && !minimized && (
                  <CircleButton onClick={maximize} className={styles.arrow}>
                    <UpArrow />
                  </CircleButton>
                )}
              </div>
            </header>
          }
          className={cx(styles.threatsList, className, {
            hideScrollBars: scrollBars,
          })}
        >
          <div className={styles.threatsLog}>
            <div className={styles.table} role="grid">
              <div className={styles.thead}>
                <div className={styles.tr} role="row">
                  <div className={cx(styles.th, styles.datetime)}>
                    Date & Time
                  </div>
                  <div className={cx(styles.th, styles.source)}>Source IP</div>
                  <div className={cx(styles.th, styles.destination)}>
                    Destination IP
                  </div>
                  <div className={cx(styles.th, styles.type)}>Threat Type</div>
                  <div className={cx(styles.th, styles.severity)}>Severity</div>
                  <div className={cx(styles.th, styles.result)}>Result</div>
                </div>
              </div>
              <div className={styles.tbody} onScroll={this.handleScroll}>
                {rows}
                <MediaQuery maxWidth={1023}>
                  <div className={styles.mobilePagination}>
                    <Button 
                      onClick={this.handleScroll} 
                      label="Load More" 
                      loading={listLoading} 
                    />
                  </div>
                </MediaQuery>
              </div>
            </div>
          </div>
        </DashboardCard>
        {isReportModalOpen && (
          <SendReportModal
            handleClose={() => this.setState({ isReportModalOpen: false })}
          />
        )}
      </>
    )
  }
}

ThreatsLog.propTypes = {
  className: PropTypes.string,
  paginateThreats: PropTypes.func.isRequired,
  sendReport: PropTypes.func.isRequired,
  threatsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      destination: PropTypes.object,
    })
  ).isRequired,
  listLoading: PropTypes.bool.isRequired,
  threatsCount: PropTypes.number.isRequired,
  maximized: PropTypes.bool,
  maximize: PropTypes.func,
  minimized: PropTypes.bool,
  minimize: PropTypes.func,
  contract: PropTypes.func,
  expand: PropTypes.func,
  zoomedCountry: PropTypes.shape({
    id: PropTypes.string,
    properties: PropTypes.object.isRequired,
  }),
}

ThreatsLog.defaultProps = {
  className: null,
  zoomedCountry: null,
  maximized: false,
  maximize: () => {},
  minimized: false,
  minimize: () => {},
  contract: () => {},
  expand: () => {},

}

const mapStateToProps = state => {
  const { list, listLoading, zoomedCountry } = state.threats
  const threatsList = list.threats
  const { threatsCount } = list.meta
  return { threatsList, listLoading, threatsCount, zoomedCountry }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      { paginateThreats: getNext, sendReport: sendRep },
      dispatch
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThreatsLog)
