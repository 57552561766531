import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PropTypes from "prop-types"

import { signinUser as signin } from "actions"
import FullscreenLoading from "components/FullscreenLoading"
import LoginForm from "components/login/Form"
import MFAForm from "components/mfa/Form"
import styles from "./Login.module.scss"

// eslint-disable-next-line import/exports-last
export class LoginRoute extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      showMFA: false,
      mfaInstructions: undefined,
      errorMsg: null,
      email: "",
      password: "",
      authToken: null, // forgot-password token
    }

    const { match } = props
    if (match.params.token) {
      this.state.fullscreenLoading = true
      this.state.authToken = match.params.token
    }
  }

  componentDidMount = () => {
    const { authToken } = this.state
    if (authToken) {
      this.handleLogin()
    }
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleLogin = ({ mfaToken = null } = {}) => {
    const { signinUser, history } = this.props
    const { email, password, authToken } = this.state

    this.setState({ loading: true })
    return signinUser({ email, password, authToken, mfaToken })
      .then(response => {
        if (response.data.nextStep === "mfaToken") {
          this.setState({
            mfaInstructions: response.data.mfaInstructions,
            fullscreenLoading: false,
            loading: false,
            showMFA: true,
          })
        } else {
          history.push("/")
        }
      })
      .catch(e => {
        this.setState({
          loading: false,
          fullscreenLoading: false,
          errorMsg:
            "Access denied. Please check your email and password and try again.",
        })
        console.error("Login error", e)
      })
  }

  render = () => {
    const {
      fullscreenLoading,
      loading,
      showMFA,
      mfaInstructions,
      errorMsg,
      email,
      password,
    } = this.state

    if (fullscreenLoading) {
      return <FullscreenLoading />
    }

    return (
      <div className={styles.loginPage}>
        <div className={styles.modalWrapper}>
          {showMFA ? (
            <MFAForm
              onSubmit={this.handleLogin}
              loading={loading}
              instructions={mfaInstructions}
            />
          ) : (
            <LoginForm
              onLogin={this.handleLogin}
              loading={loading}
              errorMsg={errorMsg}
              email={email}
              password={password}
              onChange={this.onChange}
            />
          )}
        </div>
      </div>
    )
  }
}

LoginRoute.propTypes = {
  signinUser: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ signinUser: signin }, dispatch),
  }
}

export default connect(
  null,
  mapDispatchToProps
)(LoginRoute)
