import apiCall from "utils/api"
import * as types from "./types"

const signinUser = args => dispatch => {
  let data
  if (args.email) {
    data = { email: args.email, password: args.password }
  } else {
    data = { authToken: args.authToken }
  }

  if (args.mfaToken) data = { ...data, mfaToken: args.mfaToken }

  return apiCall("post", "session", { data }).then(res => {
    if (res.data.nextStep !== "mfaToken") {
      localStorage.setItem("sessionToken", res.data.token)
      dispatch({
        type: types.SIGNIN_SUCCESS,
        payload: {
          account: res.data.account,
          provider: res.data.provider,
          user: res.data.user,
        },
      })
    }
    return res
  })
}

const signoutUser = () => dispatch =>
  apiCall("delete", "session").finally(() => {
    localStorage.removeItem("sessionToken")
    dispatch({ type: types.SIGNOUT_SUCCESS })
  })

const forgotPassword = email => dispatch => {
  const data = { email }

  return apiCall("post", "forgot-password", { data }).then(response => {
    dispatch({ type: types.FORGOT_PASSWORD_SUCCESS, payload: response })
  })
}

export { signinUser, signoutUser, forgotPassword }
