import * as types from "actions/types"

const defaultState = []

const destinations = (state = defaultState, action) => {
  switch (action.type) {
    case types.GET_DESTINATIONS:
      return action.payload
    default:
      return state
  }
}

export default destinations
