import React, { useState, useRef } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import classNames from "classnames/bind"
import PropTypes from "prop-types"

import { changeAccount as changeAcct } from "actions"
import { ReactComponent as DownCaret } from "assets/images/down-caret.svg"
import styles from "./AccountSwitcher.module.scss"

const INPUT_PADDING = 2
const cx = classNames.bind(styles)

const AccountSwitcher = ({
  currentAccount,
  availableAccounts,
  changeAccount,
}) => {
  const handleSwitchAccount = () => {
    const newAccount = filteredAccounts[highlightedIdx]
    changeAccount(newAccount.id)
    inputRef.current.blur()
    setInputWidth(newAccount.name.length + INPUT_PADDING)
    setInput(newAccount.name)
  }

  const [input, setInput] = useState(currentAccount.name)
  const [inputWidth, setInputWidth] = useState(
    currentAccount.name.length + INPUT_PADDING
  )
  const [highlightedIdx, setHighlightedIdx] = useState(0)
  const inputRef = useRef(null)

  if (availableAccounts.length < 2) {
    return <h1 className={styles.noAccountsAvailable}>{currentAccount.name}</h1>
  }

  const filteredAccounts = availableAccounts.filter(a => {
    // Input-based filter
    if (input.length > 0) {
      return (
        a.name.toLowerCase().includes(input.toLowerCase()) &&
        a.id !== currentAccount.id
      )
    }
    return a.id !== currentAccount.id
  })

  const accountOptions = filteredAccounts.map((a, index) => (
    // eslint-disable-next-line
    <li
      key={a.id}
      onFocus={() => setHighlightedIdx(index)}
      onMouseOver={() => setHighlightedIdx(index)}
      className={cx({ highlighted: index === highlightedIdx })}
      onMouseDown={handleSwitchAccount}
    >
      {a.name}
    </li>
  ))

  const handleKeyDown = e => {
    if (e.key === "ArrowUp") {
      if (highlightedIdx > 0) {
        setHighlightedIdx(highlightedIdx - 1)
      }
    } else if (e.key === "ArrowDown") {
      if (highlightedIdx < filteredAccounts.length - 1) {
        setHighlightedIdx(highlightedIdx + 1)
      }
    } else if (e.key === "Escape") {
      inputRef.current.blur()
    } else if (e.key === "Enter") {
      handleSwitchAccount()
    }
  }

  const handleFocus = e => {
    const inputField = e.target
    inputField.placeholder = input
    setInput("")
  }

  const handleArrowClick = () => {
    inputRef.current.focus()
  }

  return (
    <span className={styles.accountSwitcher}>
      {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
      <input
        value={input}
        size={inputWidth}
        className={styles.input}
        ref={inputRef}
        onChange={e => setInput(e.target.value)}
        onFocus={handleFocus}
        onBlur={() => {
          setInput(currentAccount.name)
          setHighlightedIdx(0)
        }}
        onKeyDown={handleKeyDown}
      />

      <DownCaret onClick={handleArrowClick} />
      <ul className={styles.dropdown}>{accountOptions}</ul>
    </span>
  )
}

AccountSwitcher.propTypes = {
  currentAccount: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
  }).isRequired,
  availableAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    })
  ).isRequired,
  changeAccount: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  let currentAccount
  let availableAccounts = []

  if (state.auth.account) {
    currentAccount = state.auth.account
  }
  if (state.auth.accounts) {
    availableAccounts = state.auth.accounts
  }

  return { currentAccount, availableAccounts }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ changeAccount: changeAcct }, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSwitcher)
