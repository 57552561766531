import React from "react"
import { connect } from "react-redux"
import isEqual from "lodash/isEqual"
import Odometer from "react-odometerjs"
import Chart from "chart.js"
import classNames from "classnames/bind"
import PropTypes from "prop-types"
import Squares from "react-activity/lib/Squares"

import DashboardCard from "./Card"
import "react-activity/lib/Squares/Squares.css"
import styles from "./ThreatsBlocked.module.scss"

const cx = classNames.bind(styles)

class ThreatsBlockedCard extends React.Component {
  constructor(props) {
    super(props)
    this.canvasRef = React.createRef()
  }

  componentDidUpdate = prevProps => {
    const { threatsBlocked } = this.props
    if (
      threatsBlocked.length &&
      !isEqual(prevProps.threatsBlocked, threatsBlocked)
    ) {
      const blocked = threatsBlocked.find(x => x.name === "blocked")
      const allowed = threatsBlocked.find(x => x.name === "allowed")
      this.drawChart(blocked, allowed)
    }
  }

  drawChart = (blocked, allowed) => {
    const totalBlocked = blocked.threatCounts.totalCount
    const totalAllowed = allowed.threatCounts.totalCount

    const percentageBlocked = Math.ceil(
      (totalBlocked / (totalBlocked + totalAllowed)) * 100
    )

    this.chart = new Chart(this.canvasRef.current.getContext("2d"), {
      type: "doughnut",
      data: {
        datasets: [
          {
            // [% blocked, % not blocked]
            data: [percentageBlocked, 100 - percentageBlocked],
            backgroundColor: ["#01a46d", "transparent"],
            borderWidth: 6,
            borderColor: ["rgba(0, 139, 92, 0.25)", "transparent"],
            hoverBorderWidth: 16,
          },
        ],
      },
      options: {
        cutoutPercentage: 75,
        tooltips: { enabled: false },
      },
    })
  }

  render = () => {
    const { className, threatsBlocked, loading } = this.props
    const blocked = threatsBlocked.find(x => x.name === "blocked")
    const allowed = threatsBlocked.find(x => x.name === "allowed")

    const totalBlockedCount =
      (blocked && blocked.threatCounts && blocked.threatCounts.totalCount) || 0
    const totalAllowedCount =
      (allowed && allowed.threatCounts && allowed.threatCounts.totalCount) || 0

    const totalCount = totalBlockedCount + totalAllowedCount || 0

    const popoverItems = [
      {
        label: "Critical",
        count:
          blocked && blocked.threatCounts && blocked.threatCounts.critCount,
        badgeClassName: styles.critBadge,
      },
      {
        label: "High",
        count:
          blocked && blocked.threatCounts && blocked.threatCounts.highCount,
        badgeClassName: styles.highBadge,
      },
      {
        label: "Medium",
        count: blocked && blocked.threatCounts && blocked.threatCounts.midCount,
        badgeClassName: styles.midBadge,
      },
      {
        label: "Low",
        count: blocked && blocked.threatCounts && blocked.threatCounts.lowCount,
        badgeClassName: styles.lowBadge,
      },
    ]

    return (
      <DashboardCard
        header={<span />}
        className={cx(styles.threatsBlockedCard, className)}
      >
        <div className={styles.container}>
          <div className={styles.chart}>
            <div className={styles.chartWrapper}>
              <canvas ref={this.canvasRef} />
              <div className={styles.blockedPercentage}>
                {loading 
                  ? <Squares size={16} color="white" />
                  : <span>100%</span>
                }
              </div>
            </div>
            <h5>Threats Blocked</h5>
          </div>
          <div className={styles.counts}>
            <section>
              <div className={styles.number}>
                <Odometer value={totalCount} />
              </div>
              <h5 className={styles.label}>DETECTED</h5>
            </section>
            <section>
              <div className={styles.number}>
                <Odometer value={totalBlockedCount} />
              </div>
              <h5 className={styles.label}>BLOCKED</h5>
            </section>
          </div>
        </div>
        <div className={styles.popover}>
          <ol>
            <li>
              <span>Threats Blocked</span>
              <span>{totalBlockedCount}</span>
            </li>

            {popoverItems.map(i => (
              <li key={i.label}>
                <span>
                  <span className={i.badgeClassName}>&nbsp;</span>
                  <span>{i.label}</span>
                </span>
                <span>
                  <span>{i.count}</span>
                  <span className={styles.percent}>
                    {percentageOf(i.count, totalBlockedCount)}%
                  </span>
                </span>
              </li>
            ))}
          </ol>
        </div>
      </DashboardCard>
    )
  }
}

ThreatsBlockedCard.propTypes = {
  threatsBlocked: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      threatCounts: PropTypes.object,
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  className: PropTypes.string,
}

ThreatsBlockedCard.defaultProps = {
  className: null,
}

const percentageOf = (x, y) => Math.floor((x / y) * 100)

const mapStateToProps = state => {
  const threatsBlocked = state.threats.blocked
  const loading = state.threats.blockedLoading
  return { threatsBlocked, loading }
}

export default connect(
  mapStateToProps,
  null
)(ThreatsBlockedCard)
