const serializeCountryThreats = threatsByCountry => {
  const countries = []
  threatsByCountry.forEach(country => {
    const { code, threatCounts } = country

    const threatInts = new Map()
    Object.keys(threatCounts).forEach(key => {
      threatInts.set(key, parseInt(threatCounts[key], 10))
    })

    const lowCount = threatInts.get("lowCount")
    const midCount = threatInts.get("midCount")
    const highCount = threatInts.get("highCount")
    const critCount = threatInts.get("critCount")
    const totalCount = threatInts.get("totalCount")

    let topSeverity = null
    if (critCount > 0) topSeverity = "critical"
    else if (highCount > 0) topSeverity = "high"
    else if (midCount > 0) topSeverity = "medium"
    else if (lowCount > 0) topSeverity = "low"

    // if this isn't populated, all counts are 0
    // so skip calculating totals and rendering
    if (topSeverity) {
      countries.push({
        countryCode: code,
        severity: topSeverity,
        lowCount,
        lowPercent: calculatePercentage(lowCount, totalCount),
        midCount,
        midPercent: calculatePercentage(midCount, totalCount),
        highCount,
        highPercent: calculatePercentage(highCount, totalCount),
        critCount,
        critPercent: calculatePercentage(critCount, totalCount),
        totalCount,
      })
    }
  })

  return countries
}

const calculatePercentage = (count, total) => Math.round((count / total) * 100)

// eslint-disable-next-line import/prefer-default-export
export { serializeCountryThreats }
