import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"

import styles from "./CountryFlag.module.scss"

const cx = classNames.bind(styles)

const CountryFlag = ({ code, className }) => (
  <span
    className={cx("country-flag", code.toLowerCase(), className)}
    title={code}
  />
)

CountryFlag.propTypes = {
  code: PropTypes.string.isRequired,
  className: PropTypes.string,
}

CountryFlag.defaultProps = {
  className: null,
}

export default CountryFlag
