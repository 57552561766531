import apiCall from "utils/api"
import * as types from "./types"

const getDestinations = () => dispatch =>
  apiCall("get", "destinations").then(res =>
    dispatch({ type: types.GET_DESTINATIONS, payload: res.data })
  )

const getThreatDestinations = countryCode => (dispatch, getState) => {
  const { timeframe } = getState()
  const { after, before } = countryCode ? timeframe : [null, null]

  return apiCall("get", "destinations", {
    params: { after, before, countryCode },
  }).then(res => {
    return res
  })
}

// eslint-disable-next-line import/prefer-default-export
export { getDestinations, getThreatDestinations }
