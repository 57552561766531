import React from "react"
import PropTypes from "prop-types"

import DropdownMenu from "components/DropdownMenu"
import AccountSwitcher from "components/pageHeader/AccountSwitcher"
import styles from "./PageHeader.module.scss"

const PageHeader = ({
  onOpenProfile,
  onOpenChangePass,
  onSignout,
  provider,
}) => (
  <header
    className={styles.pageHeader}
    style={{ backgroundColor: provider.color }}
  >
    <span className={styles.leftWrapper}>
      <DropdownMenu
        contentClassName={styles.hamburgerMenu}
        renderToggle={(visible, setVisible) => (
          <div className={styles.circleHover}>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <i
              className="wf-hamburger"
              onClick={() => setVisible(!visible)}
              onKeyDown={e => {
                if (e.key === "Escape") setVisible(false)
              }}
              role="button"
              tabIndex={0}
            />
          </div>
        )}
        menuItems={[
          {
            icon: "wf-profile",
            text: "Edit Profile...",
            onClick: onOpenProfile,
          },
          {
            icon: "wf-lock",
            text: "Change Password...",
            onClick: onOpenChangePass,
          },
          {
            icon: "wf-exit",
            text: "Log Out",
            onClick: onSignout,
          },
        ]}
      />
      <AccountSwitcher currentAccount />
    </span>
    {provider.image && <img src={provider.image} alt="Logo" />}
  </header>
)

PageHeader.propTypes = {
  onOpenProfile: PropTypes.func.isRequired,
  onSignout: PropTypes.func.isRequired,
  onOpenChangePass: PropTypes.func.isRequired,
  provider: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
}

export default PageHeader
