import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import get from "lodash/get"
import PropTypes from "prop-types"

import { updateUser as updateU } from "actions"
import Modal from "components/Modal"
import TextInput from "components/forms/TextInput"
import ErrorBox from "components/forms/ErrorBox"
import Button from "components/forms/Button"

import styles from "./ProfileModal.module.scss"

class ProfileModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...props.initialValues,
      loading: false,
      error: null,
    }
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  handleSubmit = event => {
    const { updateUser, userId, handleClose } = this.props
    const { firstName, lastName, phone /* , email */ } = this.state

    event.preventDefault()

    this.setState({ loading: true })
    updateUser(userId, { firstName, lastName, phone /* , email */ })
      .then(handleClose)
      .catch((e, r) => {
        console.error("Error updating user", e)
        console.log(r)
        this.setState({
          loading: false,
          error: get(
            e,
            "data.errors[0].detail",
            "An error occurred, try again."
          ),
        })
      })
  }

  render = () => {
    const { handleClose } = this.props
    const { firstName, lastName, phone, email, loading, error } = this.state

    return (
      <Modal title="Edit Profile" handleClose={handleClose}>
        <form className={styles.profileForm} onSubmit={this.handleSubmit}>
          {error && <ErrorBox message={error} />}

          <TextInput
            name="firstName"
            label="First Name"
            value={firstName}
            className={styles.textInput}
            onChange={this.handleChange}
            disabled={loading}
          />

          <TextInput
            name="lastName"
            label="Last Name"
            value={lastName}
            className={styles.textInput}
            onChange={this.handleChange}
            disabled={loading}
          />

          <TextInput
            type="phone"
            name="phone"
            label="Phone Number"
            value={phone}
            className={styles.textInput}
            onChange={this.handleChange}
            disabled={loading}
          />

          <TextInput
            type="email"
            name="email"
            label="Email Address"
            value={email}
            className={styles.textInput}
            onChange={this.handleChange}
            disabled
          />

          <Button
            className={styles.button}
            label="Update Personal Info"
            type="submit"
            loading={loading}
          />
        </form>
      </Modal>
    )
  }
}

ProfileModal.propTypes = {
  userId: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({}),
  handleClose: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
}

ProfileModal.defaultProps = {
  initialValues: {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
  },
}

const mapStateToProps = state => {
  let userId = null
  let initialValues = null

  if (state.auth.user) {
    const { id, firstName, lastName, phone, email } = state.auth.user
    userId = id
    initialValues = { firstName, lastName, phone, email }
  }

  return { userId, initialValues }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ updateUser: updateU }, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileModal)
