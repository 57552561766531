import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PropTypes from "prop-types"
import MediaQuery from "react-responsive"

import {
  getAccounts as getAccts,
  refreshData as getData,
  signoutUser as signout,
  pollThreats as doPoll,
} from "actions"
import PageHeader from "components/PageHeader"
import ThreatsByTime from "components/dashboard/ThreatsByTime"
import TimeframePicker from "components/dashboard/TimeframePicker"
import ThreatsPanel from "components/dashboard/ThreatsPanel"
import ThreatsBlockedCard from "components/dashboard/ThreatsBlocked"
import ThreatsByTypeCard from "components/dashboard/ThreatsByType"
import ThreatsBySourceCard from "components/dashboard/ThreatsBySource"
import ThreatsLog from "components/dashboard/ThreatsLog"
import ProfileModal from "components/dashboard/ProfileModal"
import ChangePassModal from "components/dashboard/ChangePassModal"
import styles from "./Dashboard.module.scss"

class DashboardPage extends React.Component {
  state = {
    isProfileOpen: false,
    isPassModalOpen: false,
    isFullScreen: false,
  }

  componentDidMount = () => {
    const { refreshData, getAccounts, pollThreats } = this.props
    refreshData()
    getAccounts() // get accounts this user is allowed to switch between

    // kick of polling
    this.pollInterval = setInterval(() => {
      pollThreats()
    }, 15000)
  }

  componentWillUnmount() {
    clearInterval(this.pollInterval)
  }

  toggleFullScreen = () => {
    const { isFullScreen } = this.state
    if (isFullScreen) {
      this.cancelFullScreen()
    } else {
      this.goFullScreen()
    }
  }

  goFullScreen = () => {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    }
    this.setState({ isFullScreen: true })
  }

  cancelFullScreen = () => {
    if (document.cancelFullScreen) {
      document.cancelFullScreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen()
    }
    this.setState({ isFullScreen: false })
  }

  render = () => {
    const { provider, signoutUser } = this.props
    const { isProfileOpen, isPassModalOpen, isFullScreen } = this.state

    return (
      <div className={styles.dashboardPage}>
        <PageHeader
          onOpenProfile={() => this.setState({ isProfileOpen: true })}
          onOpenChangePass={() => this.setState({ isPassModalOpen: true })}
          onSignout={signoutUser}
          provider={provider}
        />

        <main>
          <div className={styles.mobileHeader}>
            <ThreatsByTime className={styles.byTime} />
            <TimeframePicker
              className={styles.timeframe}
              toggleFullScreen={this.toggleFullScreen}
              expanded={isFullScreen}
            />
          </div>
          <div className={styles.container}>
            <MediaQuery minWidth={1024}>
              <ThreatsPanel provider={provider} />
            </MediaQuery>
            <div className={styles.sidebar}>
              <ThreatsBlockedCard className={styles.blocked} />
              {/* <ThreatsBySeverityCard className={styles.bySeverity} /> */}
              <ThreatsBySourceCard className={styles.bySource} />
              <ThreatsByTypeCard className={styles.byType} />
              <MediaQuery maxWidth={1023}>
                <ThreatsLog className={styles.log} />
              </MediaQuery>
            </div>
          </div>
        </main>

        {isProfileOpen && (
          <ProfileModal
            handleClose={() => this.setState({ isProfileOpen: false })}
          />
        )}
        {isPassModalOpen && (
          <ChangePassModal
            handleClose={() => this.setState({ isPassModalOpen: false })}
          />
        )}
      </div>
    )
  }
}

DashboardPage.propTypes = {
  getAccounts: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  pollThreats: PropTypes.func.isRequired,
  signoutUser: PropTypes.func.isRequired,
  provider: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
}

const mapStateToProps = state => {
  const { provider } = state.auth
  return { provider }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        getAccounts: getAccts,
        refreshData: getData,
        signoutUser: signout,
        pollThreats: doPoll,
      },
      dispatch
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardPage)
