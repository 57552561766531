import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import get from "lodash/get"
import PropTypes from "prop-types"

import { sendReport as sendRep } from "actions"
import Modal from "components/Modal"
import ErrorBox from "components/forms/ErrorBox"
import Button from "components/forms/Button"

import styles from "./ChangePassModal.module.scss"

class SendReportModal extends React.Component {
  state = {
    loading: false,
    error: null,
  }

  handleSubmit = event => {
    const { sendReport, handleClose } = this.props

    event.preventDefault()

    this.setState({ loading: true })
    sendReport()
      .then(handleClose)
      .catch(e => {
        console.error("Error sending report", e)
        this.setState({
          loading: false,
          error: get(
            e,
            "data.errors[0].detail",
            "An error occurred, try again."
          ),
        })
      })
  }

  render = () => {
    const { handleClose } = this.props
    const { loading, error } = this.state

    return (
      <Modal
        title="Email this report to your account email?"
        handleClose={handleClose}
      >
        <form className={styles.passwordForm} onSubmit={this.handleSubmit}>
          {error && <ErrorBox message={error} />}

          <Button
            className={styles.button}
            label="Email Report"
            type="submit"
            loading={loading}
          />
        </form>
      </Modal>
    )
  }
}

SendReportModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  sendReport: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ sendReport: sendRep }, dispatch),
  }
}

export default connect(
  null,
  mapDispatchToProps
)(SendReportModal)
