import React from "react"
import { connect } from "react-redux"
import classNames from "classnames/bind"
import PropTypes from "prop-types"

import CountryFlag from "components/CountryFlag"
import DashboardCard from "./Card"
import styles from "./ThreatsBySource.module.scss"

const cx = classNames.bind(styles)

const ThreatsBySourceCard = ({ threatsBySource, className }) => {
  const rows = threatsBySource.map(t => (
    <div className={styles.threat} key={t.id}>
      <div className={styles.countryFlag}>
        <CountryFlag code={t.countryCode} />
      </div>
      <div>
        <span className={styles.ip}>{t.name}</span>
        <span className={styles.countryName}>{t.countryName}</span>
      </div>
      <span>{t.threatCount} attempts</span>
    </div>
  ))

  return (
    <DashboardCard
      title="Top Threats by Source"
      className={cx(styles.threatsBySource, className)}
    >
      <div className={styles.bodyWrapper}>{rows}</div>
    </DashboardCard>
  )
}

ThreatsBySourceCard.propTypes = {
  className: PropTypes.string,
  threatsBySource: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      threatCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      countryCode: PropTypes.string,
      countryName: PropTypes.string,
    })
  ),
}

ThreatsBySourceCard.defaultProps = {
  className: null,
  threatsBySource: [],
}

const mapStateToProps = state => {
  let threatsBySource
  if (state.threats.bySource) {
    threatsBySource = state.threats.bySource
  }
  return { threatsBySource }
}

export default connect(
  mapStateToProps,
  null
)(ThreatsBySourceCard)
