/**
 * key = countryCode, like 'USA'
 * value = [lat, lon]
 */
const countryCenters = new Map([
  ["AFG", ["33", "66"]],
  ["ALB", ["41", "20"]],
  ["DZA", ["28", "3"]],
  ["ASM", ["-14.3333333", "-170"]],
  ["AND", ["42.5", "1.5"]],
  ["AGO", ["-12.5", "18.5"]],
  ["AIA", ["18.216667", "-63.05"]],
  ["ATA", ["0", "0"]],
  ["ATG", ["17.05", "-61.8"]],
  ["ARG", ["-34", "-64"]],
  ["ARM", ["40", "45"]],
  ["ABW", ["12.5", "-69.966667"]],
  ["AUS", ["-25", "135"]],
  ["AUT", ["47.333333", "13.333333"]],
  ["AZE", ["40.5", "47.5"]],
  ["BHS", ["24", "-76"]],
  ["BHR", ["26", "50.5"]],
  ["BGD", ["24", "90"]],
  ["BRB", ["13.166667", "-59.533333"]],
  ["BLR", ["53", "28"]],
  ["BEL", ["50.833333", "4"]],
  ["BLZ", ["17.25", "-88.75"]],
  ["BEN", ["9.5", "2.25"]],
  ["BMU", ["32.333333", "-64.75"]],
  ["BTN", ["27.5", "90.5"]],
  ["BOL", ["-17", "-65"]],
  ["BES", ["12.2", "-68.25"]],
  ["BIH", ["44.25", "17.833333"]],
  ["BWA", ["-22", "24"]],
  ["BVT", ["-54.433333", "3.4"]],
  ["BRA", ["-10", "-55"]],
  ["IOT", ["-6", "72"]],
  ["BRN", ["4.5", "114.666667"]],
  ["BGR", ["43", "25"]],
  ["BFA", ["13", "-2"]],
  ["BDI", ["-3.5", "30"]],
  ["KHM", ["13", "105"]],
  ["CMR", ["6", "12"]],
  ["CAN", ["60", "-96"]],
  ["CPV", ["16", "-24"]],
  ["CYM", ["19.5", "-80.666667"]],
  ["CAF", ["7", "21"]],
  ["TCD", ["15", "19"]],
  ["CHL", ["-30", "-71"]],
  ["CHN", ["35", "105"]],
  ["CXR", ["-10.5", "105.666667"]],
  ["CCK", ["-12", "96.833333"]],
  ["COL", ["4", "-72"]],
  ["COM", ["-12.166667", "44.25"]],
  ["COG", ["-1", "15"]],
  ["COD", ["0", "25"]],
  ["COK", ["-16.083333", "-161.583333"]],
  ["CRI", ["10", "-84"]],
  ["HRV", ["45.166667", "15.5"]],
  ["CUB", ["22", "-79.5"]],
  ["CUW", ["12.166667", "-69"]],
  ["CYP", ["35", "33"]],
  ["CZE", ["49.75", "15"]],
  ["DNK", ["56", "10"]],
  ["DJI", ["11.5", "42.5"]],
  ["DMA", ["15.5", "-61.333333"]],
  ["DOM", ["19", "-70.666667"]],
  ["ECU", ["-2", "-77.5"]],
  ["EGY", ["27", "30"]],
  ["SLV", ["13.833333", "-88.916667"]],
  ["GNQ", ["2", "10"]],
  ["ERI", ["15", "39"]],
  ["EST", ["59", "26"]],
  ["ETH", ["8", "38"]],
  ["EUR", ["50.833333", "4"]],
  ["FLK", ["-51.75", "-59.166667"]],
  ["FRO", ["62", "-7"]],
  ["FJI", ["-18", "178"]],
  ["FIN", ["64", "26"]],
  ["FRA", ["46", "2"]],
  ["GUF", ["4", "-53"]],
  ["PYF", ["-15", "-140"]],
  ["ATF", ["-43", "67"]],
  ["GAB", ["-1", "11.75"]],
  ["GMB", ["13.5", "-15.5"]],
  ["GEO", ["41.999981", "43.499905"]],
  ["DEU", ["51.5", "10.5"]],
  ["GHA", ["8", "-2"]],
  ["GIB", ["36.133333", "-5.35"]],
  ["GRC", ["39", "22"]],
  ["GRL", ["72", "-40"]],
  ["GRD", ["12.116667", "-61.666667"]],
  ["GLP", ["16.25", "-61.583333"]],
  ["GUM", ["13.4444444", "144.7366667"]],
  ["GTM", ["15.5", "-90.25"]],
  ["GGY", ["49.583333", "-2.333333"]],
  ["GIN", ["11", "-10"]],
  ["GNB", ["12", "-15"]],
  ["GUY", ["5", "-59"]],
  ["HTI", ["19", "-72.416667"]],
  ["HMD", ["-53", "73"]],
  ["VAT", ["41.9", "12.45"]],
  ["HND", ["15", "-86.5"]],
  ["HKG", ["22.25", "114.166667"]],
  ["HUN", ["47", "20"]],
  ["ISL", ["65", "-18"]],
  ["IND", ["20", "77"]],
  ["IDN", ["-5", "120"]],
  ["IRN", ["32", "53"]],
  ["IRQ", ["33", "44"]],
  ["IRL", ["53", "-8"]],
  ["IMN", ["54.25", "-4.5"]],
  ["ISR", ["31.5", "34.75"]],
  ["ITA", ["42.833333", "12.833333"]],
  ["CIV", ["8", "-5"]],
  ["JAM", ["18.25", "-77.5"]],
  ["JPN", ["36", "138"]],
  ["JEY", ["49.216667", "-2.116667"]],
  ["JOR", ["31", "36"]],
  ["KAZ", ["48", "68"]],
  ["KEN", ["1", "38"]],
  ["KIR", ["-5", "-170"]],
  ["PRK", ["40", "127"]],
  ["KOR", ["37", "127.5"]],
  ["KWT", ["29.5", "47.75"]],
  ["KGZ", ["41", "75"]],
  ["LAO", ["16.166667", "107.833333"]],
  ["LVA", ["57", "25"]],
  ["LBN", ["33.833333", "35.833333"]],
  ["LSO", ["-29.5", "28.25"]],
  ["LBR", ["6.5", "-9.5"]],
  ["LBY", ["25", "17"]],
  ["LIE", ["47.166667", "9.533333"]],
  ["LTU", ["56", "24"]],
  ["LUX", ["49.75", "6.166667"]],
  ["MAC", ["22.157778", "113.559722"]],
  ["MKD", ["41.833333", "22"]],
  ["MDG", ["-20", "47"]],
  ["MWI", ["-13.5", "34"]],
  ["MYS", ["2.5", "112.5"]],
  ["MDV", ["3.2", "73"]],
  ["MLI", ["17", "-4"]],
  ["MLT", ["35.916667", "14.433333"]],
  ["MHL", ["10", "167"]],
  ["MTQ", ["14.666667", "-61"]],
  ["MRT", ["20", "-12"]],
  ["MUS", ["-20.3", "57.583333"]],
  ["MYT", ["-12.833333", "45.166667"]],
  ["MEX", ["23", "-102"]],
  ["FSM", ["5", "152"]],
  ["MDA", ["47", "29"]],
  ["MCO", ["43.733333", "7.4"]],
  ["MNG", ["46", "105"]],
  ["MNE", ["42.5", "19.3"]],
  ["MSR", ["16.75", "-62.2"]],
  ["MAR", ["32", "-5"]],
  ["MOZ", ["-18.25", "35"]],
  ["MMR", ["22", "98"]],
  ["NAM", ["-22", "17"]],
  ["NRU", ["-0.533333", "166.916667"]],
  ["NPL", ["28", "84"]],
  ["NLD", ["52.5", "5.75"]],
  ["NCL", ["-21.5", "165.5"]],
  ["NZL", ["-42", "174"]],
  ["NIC", ["13", "-85"]],
  ["NER", ["16", "8"]],
  ["NGA", ["10", "8"]],
  ["NIU", ["-19.033333", "-169.866667"]],
  ["NFK", ["-29.033333", "167.95"]],
  ["MNP", ["16", "146"]],
  ["NOR", ["62", "10"]],
  ["OMN", ["21", "57"]],
  ["PAK", ["30", "70"]],
  ["PLW", ["6", "134"]],
  ["PSE", ["31.666667", "35.25"]],
  ["PAN", ["9", "-80"]],
  ["PNG", ["-6", "147"]],
  ["PRY", ["-22.993333", "-57.996389"]],
  ["PER", ["-10", "-76"]],
  ["PHL", ["13", "122"]],
  ["PCN", ["-25.066667", "-130.1"]],
  ["POL", ["52", "20"]],
  ["PRT", ["39.5", "-8"]],
  ["PRI", ["18.2482882", "-66.4998941"]],
  ["QAT", ["25.5", "51.25"]],
  ["REU", ["-21.1", "55.6"]],
  ["ROU", ["46", "25"]],
  ["RUS", ["60", "100"]],
  ["RWA", ["-2", "30"]],
  ["BLM", ["17.9", "-62.833333"]],
  ["SHN", ["-15.95", "-5.7"]],
  ["KNA", ["17.333333", "-62.75"]],
  ["LCA", ["13.883333", "-60.966667"]],
  ["SXM", ["18.04167", "-63.06667"]],
  ["MAF", ["18.075", "-63.05833"]],
  ["SPM", ["46.833333", "-56.333333"]],
  ["VCT", ["13.083333", "-61.2"]],
  ["WSM", ["-13.803096", "-172.178309"]],
  ["SMR", ["43.933333", "12.416667"]],
  ["STP", ["1", "7"]],
  ["SAU", ["25", "45"]],
  ["SEN", ["14", "-14"]],
  ["SRB", ["44", "21"]],
  ["SYC", ["-4.583333", "55.666667"]],
  ["KAS", ["0", "0"]],
  ["SLE", ["8.5", "-11.5"]],
  ["SGP", ["1.366667", "103.8"]],
  ["SVK", ["48.666667", "19.5"]],
  ["SVN", ["46.25", "15.166667"]],
  ["SLB", ["-8", "159"]],
  ["SOM", ["6", "48"]],
  ["ZAF", ["-30", "26"]],
  ["SGS", ["-56", "-33"]],
  ["SSD", ["8", "30"]],
  ["ESP", ["40", "-4"]],
  ["LKA", ["7", "81"]],
  ["SDN", ["16", "30"]],
  ["SUR", ["4", "-56"]],
  ["SJM", ["78", "20"]],
  ["SWZ", ["-26.5", "31.5"]],
  ["SWE", ["62", "15"]],
  ["CHE", ["47", "8"]],
  ["SYR", ["35", "38"]],
  ["TWN", ["24", "121"]],
  ["TJK", ["39", "71"]],
  ["TZA", ["-6", "35"]],
  ["THA", ["15", "100"]],
  ["TLS", ["-8.833333", "125.75"]],
  ["TGO", ["8", "1.166667"]],
  ["TKL", ["-9", "-171.75"]],
  ["TON", ["-20", "-175"]],
  ["TTO", ["11", "-61"]],
  ["TUN", ["34", "9"]],
  ["TUR", ["39.059012", "34.911546"]],
  ["TKM", ["40", "60"]],
  ["TCA", ["21.733333", "-71.583333"]],
  ["TUV", ["-8", "178"]],
  ["UGA", ["2", "33"]],
  ["UKR", ["49", "32"]],
  ["ARE", ["24", "54"]],
  ["GBR", ["54", "-4"]],
  ["USA", ["39.828175", "-98.5795"]],
  ["UMI", ["5.8811111", "-162.0725"]],
  ["URY", ["-33", "-56"]],
  ["UZB", ["41.707542", "63.84911"]],
  ["VUT", ["-16", "167"]],
  ["VEN", ["8", "-66"]],
  ["VNM", ["16.166667", "107.833333"]],
  ["VIR", ["18.3482891", "-64.9834807"]],
  ["VGB", ["18.5", "-64.5"]],
  ["WLF", ["-13.3", "-176.2"]],
  ["ESH", ["25", "-13.5"]],
  ["YEM", ["15.5", "47.5"]],
  ["ZMB", ["-15", "30"]],
  ["ZWE", ["-19", "29"]],
])

export default countryCenters
