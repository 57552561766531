import { createStore, applyMiddleware } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import thunk from "redux-thunk"

import reducers from "./reducers"

// prettier-ignore
// eslint-disable-next-line import/exports-last
export const configureStore = initialState => (
  composeWithDevTools(
    applyMiddleware(thunk)
  )(createStore)(reducers, initialState)
)

const store = configureStore()

export default store
