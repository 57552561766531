import React from "react"

import "./ThreatsLegend.scss"

const ThreatsLegend = () => (
  <div className="threatsLegend">
    <h6>Threat Level</h6>
    <table>
      <tbody>
        <tr>
          <td>
            <span className="threat-severity-block critical" />
          </td>
          <td>Critical</td>
        </tr>
        <tr>
          <td>
            <span className="threat-severity-block high" />
          </td>
          <td>High</td>
        </tr>
        <tr>
          <td>
            <span className="threat-severity-block medium" />
          </td>
          <td>Medium</td>
        </tr>
        <tr>
          <td>
            <span className="threat-severity-block low" />
          </td>
          <td>Low</td>
        </tr>
      </tbody>
    </table>
  </div>
)

export default ThreatsLegend
