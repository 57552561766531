// Timeframe
export const SELECT_TIMEFRAME_OPTION = "SELECT_TIMEFRAME_OPTION"
export const CHANGE_TIMEFRAME_CUSTOM = "CHANGE_TIMEFRAME_CUSTOM"
export const SET_POLLING_TIMEFRAME = "SET_POLLING_TIMEFRAME"
export const SET_POLLED_THREATS = "SET_POLLED_THREATS"

// Authentication
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS"
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS"
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS"
export const GET_ACCOUNTS = "GET_ACCOUNTS"
export const CHANGE_ACCOUNT = "CHANGE_ACCOUNT"

// Destinations
export const GET_DESTINATIONS = "GET_DESTINATIONS"

// User
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"

// Threats
export const THREATS_LIST = "THREATS_LIST"
export const PAGINATE_THREATS_LIST = "PAGINATE_THREATS_LIST"
export const PAGINATE_THREATS_LIST_REQUEST = "PAGINATE_THREATS_LIST_REQUEST"
export const THREATS_BY_COUNTRY = "THREATS_BY_COUNTRY"
export const THREATS_BLOCKED_REQUEST = "THREATS_BLOCKED_REQUEST"
export const THREATS_BLOCKED = "THREATS_BLOCKED"
export const THREATS_BY_TYPE = "THREATS_BY_TYPE"
export const THREATS_BY_SOURCE = "THREATS_BY_SOURCE"
export const THREATS_BY_TIME = "THREATS_BY_TIME"
export const CLEAR_POLLED_THREATS = "CLEAR_POLLED_THREATS"
export const ZOOM_COUNTRY = "ZOOM_COUNTRY"

// Reports
export const REPORT_SENT = "REPORT_SENT"
