import React from "react"
import Squares from "react-activity/lib/Squares"

import "react-activity/lib/Squares/Squares.css"
import styles from "./FullscreenLoading.module.scss"

const FullscreenLoading = () => (
  <div className={styles.fullscreenLoading}>
    <Squares size={32} color="white" />
  </div>
)

export default FullscreenLoading
