import apiCall from "utils/api"
import { refreshData } from "./threats"
import * as types from "./types"

const getAccounts = () => dispatch =>
  apiCall("get", "accounts").then(res =>
    dispatch({ type: types.GET_ACCOUNTS, payload: res.data })
  )

const changeAccount = accountId => dispatch =>
  apiCall("patch", "session", { data: { accountId } }).then(res => {
    dispatch({
      // FYI this action is handled in the root reducer (reducers/index.js)
      type: types.CHANGE_ACCOUNT,
      payload: {
        account: res.data.account,
        provider: res.data.provider,
        user: res.data.user,
      },
    })
    dispatch(refreshData())
  })

export { getAccounts, changeAccount }
