import apiCall from "utils/api"
import * as types from "./types"

/* Validate session and get user data after browser close/refresh */
const getCurrentUser = () => dispatch =>
  apiCall("get", "session").then(res => {
    dispatch({
      type: types.SIGNIN_SUCCESS,
      payload: {
        account: res.data.account,
        provider: res.data.provider,
        user: res.data.user,
      },
    })
  })

const updateUser = (userId, data) => dispatch => {
  return apiCall("patch", `users/${userId}`, { data }).then(res => {
    dispatch({ type: types.UPDATE_USER_SUCCESS, payload: res.data })
  })
}

export { getCurrentUser, updateUser }
