import React from "react"
import moment from "moment"
import classNames from "classnames/bind"
import PropTypes from "prop-types"
import MediaQuery from "react-responsive"

import CountryFlag from "components/CountryFlag"
import styles from "./ThreatsLog.module.scss"

const cx = classNames.bind(styles)

const ThreatsRow = ({ threat, showFlag }) => {

  return (
    <div className={styles.tr} role="row">
      <MediaQuery maxWidth={1023}>
        <CountryFlag code={threat.source.country.code} className={styles.flag} />
        <div className={styles.locations}>
          <div className={cx(styles.td, styles.source)}>
            <h5 className={styles.label}>Source IP</h5>
            <span className={styles.sourceIp}>{threat.source.name}</span>
            <span className={styles.sourceCountry}>{threat.source.country.name}</span>
          </div>
          <div className={cx(styles.td, styles.destination)}>
            <h5 className={styles.label}>Destination IP</h5>
            <span className={styles.destinationIp}>{threat.destination.name}</span>
            <span className={styles.destinationName}>
              {threat.destination.location.name}
            </span>
          </div>
          <div className={cx(styles.td, styles.type, styles.tablet)}>
            <h5 className={styles.label}>Threat Type</h5>
            <span>{threat.type.name} ({threat.type.id})</span>
          </div>
        </div>
        <div className={styles.details}>
          <div className={cx(styles.td, styles.datetime)}>
            <span className={styles.timestamp}>
              {moment(threat.createdAt).format("Y-MM-DD HH:mm:ss")}
            </span>
            <div className={cx(styles.td, styles.severity)}>
              <span className={cx(threat.severity)}>{threat.severity}</span>
            </div>
            <div className={cx(styles.td, styles.result)}>
              <span className={styles.action}>{threat.action}</span>
            </div>
          </div>
          <div className={cx(styles.td, styles.type, styles.mobile)}>
            <h5 className={styles.label}>Threat Type</h5>
            {threat.type.name} ({threat.type.id})
          </div>
        </div>
      </MediaQuery>
      <MediaQuery minWidth={1024}>
        <div className={cx(styles.td, styles.datetime)}>
          {moment(threat.createdAt).fromNow()}
        </div>
        <div className={cx(styles.td, styles.source)}>
          {showFlag && (
            <CountryFlag code={threat.source.country.code} className={styles.flag} />
          )}
          <span className={styles.sourceIp}>{threat.source.name}</span>
        </div>
        <div className={cx(styles.td, styles.destination)}>
          {threat.destination.name}
          <span className={styles.destinationName}>
            {threat.destination.location.name}
          </span>
        </div>
        <div className={cx(styles.td, styles.type)}>
          {threat.type.name} ({threat.type.id})
        </div>
        <div className={cx(styles.td, styles.severity)}>
          <span className={cx(threat.severity)}>{threat.severity}</span>
        </div>
        <div className={cx(styles.td, styles.result)}>
          <span className={styles.action}>{threat.action}</span>
        </div>
      </MediaQuery>
    </div>
  )
}

ThreatsRow.propTypes = {
  threat: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    datetime: PropTypes.string,
    source: PropTypes.object,
    destination: PropTypes.object,
    type: PropTypes.object,
    severity: PropTypes.string,
    action: PropTypes.string,
    createdAt: PropTypes.string
  }).isRequired,
  showFlag: PropTypes.bool.isRequired,
}

export default ThreatsRow
