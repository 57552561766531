import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Switch, Route } from "react-router-dom"
import PropTypes from "prop-types"

import { getCurrentUser as getUser } from "actions"
import FullscreenLoading from "components/FullscreenLoading"
import PrivateRoute from "components/routes/Private"
import LoginRoute from "components/routes/Login"
import ForgotPasswordRoute from "components/routes/ForgotPassword"
import DashboardPage from "components/routes/Dashboard"

// eslint-disable-next-line import/exports-last
export class App extends React.Component {
  state = {
    loading: true,
  }

  componentDidMount = () => {
    const { getCurrentUser } = this.props
    getCurrentUser()
      .catch(() => {
        console.error("Couldn't validate session, kicking to login...")
        // Kick to login will happen in utils/api.js
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  render = () => {
    const { loading } = this.state

    if (loading) {
      return <FullscreenLoading />
    }

    return (
      <div className="App">
        <Switch>
          <Route exact path="/login" component={LoginRoute} />
          <Route exact path="/forgot-password/:token" component={LoginRoute} />
          <Route
            exact
            path="/forgot-password"
            component={ForgotPasswordRoute}
          />
          <PrivateRoute exact path="/" component={DashboardPage} />
        </Switch>
      </div>
    )
  }
}

App.propTypes = {
  getCurrentUser: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ getCurrentUser: getUser }, dispatch),
  }
}

export default connect(
  null,
  mapDispatchToProps
)(App)
