import React from "react"
import ReactDOM from "react-dom"
import classNames from "classnames/bind"
import PropTypes from "prop-types"

import { ReactComponent as CloseButton } from "assets/images/close-button.svg"
import styles from "./Modal.module.scss"

const cx = classNames.bind(styles)

const modalRoot = document.getElementById("modal-root")

const Modal = ({ title, className, children, handleClose }) =>
  ReactDOM.createPortal(
    <div className={styles.overlay}>
      <div className={cx(styles.content, className)}>
        <header>
          <h3>{title}</h3>
          <div className={styles.circleHover}>
            <div
              className={styles.closeButton}
              onClick={handleClose}
              tabIndex={0}
              role="button"
              onKeyDown={e => {
                if (e.key === " ") handleClose()
              }}
            >
              <CloseButton />
            </div>
          </div>
        </header>
        {children}
      </div>
    </div>,
    modalRoot
  )

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default Modal
