import React from "react"
import classNames from "classnames/bind"
import PropTypes from "prop-types"

import styles from "./TextInput.module.scss"

const cx = classNames.bind(styles)

const TextInput = React.forwardRef(
  (
    {
      type,
      name,
      label,
      value,
      onChange,
      className,
      disabled,
      pattern,
      minLength,
      autoComplete,
      required,
    },
    ref
  ) => (
    <div className={cx(styles.textInput, className)}>
      <label htmlFor={label}>{label}</label>
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        pattern={pattern}
        minLength={minLength}
        autoComplete={autoComplete}
        required={required}
        ref={ref}
      />
    </div>
  )
)

TextInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  pattern: PropTypes.string,
  minLength: PropTypes.number,
  autoComplete: PropTypes.string,
  required: PropTypes.bool,
}

TextInput.defaultProps = {
  type: "text",
  className: null,
  disabled: false,
  pattern: null,
  minLength: undefined,
  autoComplete: undefined,
  required: false,
}

export default TextInput
