import moment from "moment"
import * as types from "./types"
import { refreshData } from "./threats"

const selectTimeframeOption = newTimeframe => dispatch => {
  dispatch({
    type: types.SELECT_TIMEFRAME_OPTION,
    payload: newTimeframe,
  })
  dispatch(refreshData())
}

const changeTimeFrameCustom = (after, before) => dispatch => {
  const afterDT = moment(after).toISOString()
  const beforeDT = moment(before)
    .endOf("day")
    .toISOString()

  console.log("change", before, after)

  dispatch({
    type: types.CHANGE_TIMEFRAME_CUSTOM,
    after: afterDT,
    before: beforeDT,
  })
  dispatch(refreshData())
}

export { selectTimeframeOption, changeTimeFrameCustom }
