import { combineReducers } from "redux"

import * as types from "actions/types"
import auth from "./auth"
import destinations from "./destinations"
import threats from "./threats"
import timeframe from "./timeframe"

const appReducer = combineReducers({
  auth,
  destinations,
  threats,
  timeframe,
})

const rootReducer = (state, action) => {
  if (action.type === types.CHANGE_ACCOUNT) {
    const newState = {
      ...state,

      // Reset threats reducer to its initial values
      // threats: undefined,
    }

    const { account, user, provider } = action.payload
    newState.auth.account = account
    newState.auth.provider = provider
    newState.auth.user = user

    return appReducer(newState, action)
  }
  return appReducer(state, action)
}

export default rootReducer
