/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import { connect } from "react-redux"
import { Route, Redirect } from "react-router-dom"
import PropTypes from "prop-types"

// eslint-disable-next-line import/exports-last
export const PrivateRoute = ({
  component: Component,
  authenticated,
  ...rest
}) => (
  <Route
    {...rest}
    render={routeProps =>
      authenticated ? (
        <Component {...routeProps} {...rest} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: routeProps.location },
          }}
        />
      )
    }
  />
)

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.elementType])
    .isRequired,
  authenticated: PropTypes.bool,
}

PrivateRoute.defaultProps = {
  authenticated: false,
}

const mapStateToProps = state => ({
  authenticated: state.auth.authenticated,
  user: state.auth && state.auth.user,
})

export default connect(mapStateToProps)(PrivateRoute)
