import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import classNames from "classnames/bind"
import PropTypes from "prop-types"

import {
  selectTimeframeOption as selectTf,
  changeTimeFrameCustom as changeTf,
} from "actions"
import DropdownMenu from "components/DropdownMenu"
import DateRange from "components/dashboard/DateRange"
import { ReactComponent as SortIcon } from "assets/images/sort-solid.svg"
import { ReactComponent as ExpandIcon } from "assets/images/expand.svg"
import { ReactComponent as ContractIcon } from "assets/images/contract.svg"
import styles from "./TimeframePicker.module.scss"

const cx = classNames.bind(styles)

const timeframeOptions = [
  "Past 24 Hours",
  "Past 48 Hours",
  "Past 72 Hours",
  "Past 7 Days",
  "Past 30 Days",
  "Past 90 Days",
]

const TimeframePicker = ({
  timeframe,
  selectTimeframeOption,
  changeTimeFrameCustom,
  className,
  toggleFullScreen,
  expanded,
}) => (
  <div className={cx(styles.timeframePicker, className)}>
    <div className={styles.mobileTimeControls}>
      <DateRange
        before={timeframe.before}
        after={timeframe.after}
        handleChange={changeTimeFrameCustom}
      />
      {timeframe.isLive && <div className={styles.liveIndicator}>LIVE</div>}
    </div>
    <div className={styles.dropdownUi}>
      <DropdownMenu
        alignRight
        renderToggle={(visible, setVisible) => (
          <div
            className={styles.timeframeButton}
            onClick={() => setVisible(!visible)}
            onKeyDown={e => {
              if (e.key === "Escape") setVisible(false)
            }}
            role="button"
            tabIndex={0}
          >
            {timeframe.text}
            <SortIcon className={styles.sortIcon} />
          </div>
        )}
        menuItems={timeframeOptions.map(o => ({
          text: o,
          onClick: () => selectTimeframeOption(o),
        }))}
      />
    </div>

    {/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */}
    <div className={styles.desktopTimeControls}>
      <div className={styles.listUi}>
        <ol>
          {timeframeOptions.map(o => (
            <li
              key={o}
              role="button"
              onClick={() => selectTimeframeOption(o)}
              className={cx({ active: timeframe.text === o })}
              tabIndex={0}
              onKeyDown={e => {
                if (e.key === " ") selectTimeframeOption(o)
              }}
            >
              {o}
            </li>
          ))}
          <li
            className={cx("dateRange", { active: timeframe.text === "custom" })}
          >
            <DateRange
              before={timeframe.before}
              after={timeframe.after}
              handleChange={changeTimeFrameCustom}
            />
          </li>
        </ol>
      </div>
      {timeframe.isLive && <div className={styles.liveIndicator}>LIVE</div>}
    </div>
    <button className={styles.expandButton} onClick={toggleFullScreen}>
      {expanded ? (
        <>
          <span>Exit fullscreen</span>
          <ContractIcon />
        </>
      ) : (
        <>
          <span>Fullscreen</span>
          <ExpandIcon />
        </>
      )}
    </button>
  </div>
)

TimeframePicker.propTypes = {
  timeframe: PropTypes.shape({
    text: PropTypes.string.isRequired,
    after: PropTypes.string.isRequired,
    before: PropTypes.string.isRequired,
    isLive: PropTypes.bool.isRequired,
  }).isRequired,
  selectTimeframeOption: PropTypes.func.isRequired,
  changeTimeFrameCustom: PropTypes.func.isRequired,
  className: PropTypes.string,
  toggleFullScreen: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
}

TimeframePicker.defaultProps = {
  className: null,
}

const mapStateToProps = state => ({
  timeframe: state.timeframe,
})

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      { selectTimeframeOption: selectTf, changeTimeFrameCustom: changeTf },
      dispatch
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimeframePicker)
