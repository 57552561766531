import apiCall from "utils/api"
import moment from "moment"
import * as types from "./types"
import { getDestinations } from "./destinations"

const refreshData = () => (dispatch, getState) => {
  const { timeframe, threats } = getState()
  const { after, before } = timeframe
  const { zoomedCountry } = threats

  dispatch(getDestinations())
  dispatch(getThreatsByCountry(after, before))
  dispatch(getThreatsBlocked(after, before))
  dispatch(getThreatsByType(after, before))
  dispatch(getThreatsBySource(after, before))
  if (!zoomedCountry) {
    // don't refresh the threats log if we are zoomed on a specific country
    dispatch(getThreats(after, before))
  }
  dispatch(getThreatsByTime())
}

// initial fetch of threats for the log - when account or timeframe changes
const getThreats = (after, before, countryCode) => dispatch => {
  const offset = 0
  const limit = 50

  apiCall("get", "threats", {
    params: { after, before, offset, limit, countryCode },
  }).then(res => {
    dispatch({
      type: types.THREATS_LIST,
      payload: res.data,
    })
  })
}

const paginateThreats = (offset = 0, limit = 50) => (dispatch, getState) => {
  const { timeframe, threats } = getState()
  const { after, before } = timeframe
  const { zoomedCountry } = threats
  const afterUTC = moment(after).toISOString()
  const beforeUTC = moment(before).toISOString()
  const countryCode = zoomedCountry ? zoomedCountry.id : null

  dispatch({ type: types.PAGINATE_THREATS_LIST_REQUEST })
  apiCall("get", "threats", {
    params: { after: afterUTC, before: beforeUTC, offset, limit, countryCode },
  }).then(res => {
    dispatch({
      type: types.PAGINATE_THREATS_LIST,
      payload: res.data,
    })
  })
}

const pollThreats = () => (dispatch, getState) => {
  const { timeframe } = getState()

  if (timeframe.isLive) {
    const after = timeframe.lastPoll
    const before = moment().toISOString()
    const offset = 0
    const limit = 50
    dispatch({
      type: types.SET_POLLING_TIMEFRAME,
      payload: before,
    })
    dispatch(refreshData())

    apiCall("get", "threats", {
      params: { after, before, offset, limit },
    }).then(res => {
      dispatch({
        type: types.SET_POLLED_THREATS,
        payload: res.data,
      })
    })
  }
}

const clearPollThreats = () => dispatch =>
  dispatch({
    type: types.CLEAR_POLLED_THREATS,
  })

const zoomCountry = country => (dispatch, getState) => {
  const { timeframe } = getState()
  const { after, before } = timeframe
  const countryCode = country ? country.id : null
  dispatch(getThreats(after, before, countryCode))

  dispatch({
    type: types.ZOOM_COUNTRY,
    payload: country,
  })
}

const getThreatsByCountry = (after, before) => dispatch =>
  apiCall("get", "threats/countries", { params: { after, before } }).then(
    res => {
      dispatch({
        type: types.THREATS_BY_COUNTRY,
        payload: res.data,
      })
    }
  )

const getThreatsBlocked = (after, before) => dispatch => {
  dispatch({ type: types.THREATS_BLOCKED_REQUEST })
  return apiCall("get", "threats/status", { params: { after, before } }).then(
    res => {
      dispatch({
        type: types.THREATS_BLOCKED,
        payload: res.data,
      })
    }
  )
}

const getThreatsByType = (after, before) => dispatch =>
  apiCall("get", "threats/types", { params: { after, before } }).then(res => {
    dispatch({
      type: types.THREATS_BY_TYPE,
      payload: res.data,
    })
  })

const getThreatsBySource = (after, before) => dispatch =>
  apiCall("get", "threats/sources", { params: { after, before } }).then(res => {
    dispatch({
      type: types.THREATS_BY_SOURCE,
      payload: res.data,
    })
  })

const getThreatsByTime = () => dispatch =>
  apiCall("get", "threats/time", {
    params: {
      after: moment()
        .subtract(90, "days")
        .format("Y-MM-DD"),
      before: moment().format("Y-MM-DD"),
    },
  }).then(res => {
    dispatch({
      type: types.THREATS_BY_TIME,
      payload: res.data,
    })
  })

export {
  refreshData,
  getThreats,
  paginateThreats,
  pollThreats,
  clearPollThreats,
  zoomCountry,
  getThreatsByCountry,
  getThreatsBlocked,
  getThreatsByType,
  getThreatsBySource,
  getThreatsByTime,
}
